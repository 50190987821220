// 中转仓库
import * as type from "../mutation-types"
const state = {
  data:window.localStorage.getItem("transitWarehouse") || ""
}

const getters = {
  getTransitWarehouse:state=>state.data
}

const mutations = {
  [type.USER_OUT_LOGIN](state){
    state.data = null
  }
}

const actions = {
  onUse(response){
    window.localStorage.setItem("transitWarehouse",response)
  },
  outLogin({commit}){
    commit(type.USER_OUT_LOGIN)
    window.localStorage.removeItem("transitWarehouse")
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
