// 获取权限按钮,返回内容为对应当前页面存在权限的所有按钮集合
import AES from '@/mixins/AES';

export default {
  install(Vue) {
    Vue.prototype.getPermissionButton = function() {
      let menus = JSON.parse(AES.decrypt(window.localStorage.getItem("menuList")))
      let pages = []
      menus.map((menu) => { //提取菜单
        if (menu.menuId === this.$route.meta.menuId) {
          menu.children.map((catalogue) => {  //提取目录
            if (catalogue.menuId === this.$route.meta.catalogueId) {
              catalogue.children.map((page) => { //提取页面
                if (page.menuId === this.$route.meta.pageId) {
                  pages = page.children
                }
              })
            }
          })
        }
      })
      return pages
    };
  }
}