export default {
  install(Vue) {
    Vue.prototype.copy = function(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        this.$message.success(this.$t('Successfully_copied_to_clipboard'))
      } catch (err) {
        this.$message.error(this.$t('Unable_to_copy_to_clipboard'));
      }
      document.body.removeChild(textArea);
    };
  }
}