import * as type from "../mutation-types"

const state = {
    isCollapse: window.localStorage.getItem("isCollapse") === "true" ? true : false,//控制菜单是否折叠 true:折叠 false:展开
}

const getters = {
    getIsCollapse: state => state.isCollapse
}

const mutations = {
    [type.MENU_EXPAND](state) {
        state.isCollapse = false
        window.localStorage.setItem("isCollapse",false)
    },
    [type.MENU_FOLD](state) {
        state.isCollapse = true
        window.localStorage.setItem("isCollapse",true)
    },
}

const actions = {
    onFlod({ commit }) {
        commit(type.MENU_FOLD)
    },
    onExpand({ commit }) {
        commit(type.MENU_EXPAND)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}