import * as type from "../mutation-types"

const state = {
  miniWorkbenchIsCollapse: false
}

const getters = {
  getMiniWorkbenchIsCollapse: state => state.miniWorkbenchIsCollapse
}

const mutations = {
  [type.MiniWorkbenchCollapse_EXPAND](state) {
    state.miniWorkbenchIsCollapse = false
    window.localStorage.setItem("miniWorkbenchIsCollapse",false)
  },
  [type.MiniWorkbenchCollapse_FOLD](state) {
    state.miniWorkbenchIsCollapse = true
    window.localStorage.setItem("miniWorkbenchIsCollapse",true)
  },
}

const actions = {
  onMiniWorkbenchFlod({ commit }) {
    commit(type.MiniWorkbenchCollapse_FOLD)
  },
  onMiniWorkbenchExpand({ commit }) {
    commit(type.MiniWorkbenchCollapse_EXPAND)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}