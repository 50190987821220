
import * as type from "../mutation-types"
import AES from '@/mixins/AES'
const state={
  token:window.localStorage.getItem("token") || "",
  MenuList:window.localStorage.getItem('menuList')
}
const getters={
  getToken:state=>state.token,
  getMenuList:state=>state.menuList,
}

const mutations={
  [type.USER_LOGIN_SUCCESS](state,response){
    state.token=response.return_token
    state.MenuList=response.menuList
  },
  [type.USER_OUT_LOGIN](state){
    state.token=null
    state.MenuList=null
  }
}

const actions={
  onLogin({commit},response){
    commit(type.USER_LOGIN_SUCCESS,response)
    let token = `Bearer ${response.token}`
    window.localStorage.setItem("token",token)
    window.localStorage.setItem("menuList",AES.encrypt(JSON.stringify(response.role[0].children)))
    window.localStorage.setItem("server",response.server)
  },
  outLogin({commit}){
    commit(type.USER_OUT_LOGIN)
    window.localStorage.removeItem("token")
    window.localStorage.removeItem("menuList")
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}