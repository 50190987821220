import axios from "axios";
import Vue from "vue";
//import {Message} from "element-ui"
import api from "./api"
// 创建一个独立的axios实例

const service = axios.create({ 
    // 设置baseUr地址,如果通过proxy跨域可直接填写base地址
    baseURL: api,
    // 配置请求超时时间 
    timeout: 20000, 
    // 如果用的JSONP，可以配置此参数带上cookie凭证，如果是代理和CORS不用设置
    //withCredentials: true
});
// 请求拦截
service.interceptors.request.use(config => {
    // 自定义header，可添加项目token
    if (window.localStorage.getItem("token")) {
        config.headers.Authorization = window.localStorage.getItem("token") || '';
    }
    
    return config;
});
// 返回拦截
service.interceptors.response.use((response) => {
    if(response.message=="终止请求"){
        return
    }
    if(response.data=="无效的授权信息或授权信息已过期，请重新登录！"){
        Vue.prototype.$confirm('无效的授权信息或授权信息已过期，请重新登录！', '系统通知', {
            confirmButtonText: '跳转登录页面',
            showCancelButton:false,
            showClose:false,
            closeOnClickModal:false,//取消点击遮罩关闭 MessageBox
            closeOnPressEscape:false,//取消 ESC 键关闭 MessageBox
            customClass: 'confirm',
            type: 'error'
        }).then(()=>{
            // Vue.$router.replace("/login")
            window.location.href="/"
        })
        return
    }
    if(response.data=="禁止访问，没有权限！"){
        Vue.prototype.$confirm('禁止访问，没有权限！', '系统通知', {
            confirmButtonText: '确定',
            type: 'error'
        })
        return
    }
    if(response.status==200){
        return Promise.resolve(response.data)
    }
    else {
        Vue.prototype.$confirm('接口通讯失败，请尝试刷新页面或者重新登录。如果还是存在问题，请联系管理员[warning]', '系统通知', { 
            confirmButtonText: '确定',
            type: 'error'
        })
    }
}, (error) => {
    if (error.response.status > 399 && error.response.status < 500) {
        Vue.prototype.$confirm(`存在未填写的必填项，通讯失败。\n[error]: ${error.message}`, '系统通知', {
            confirmButtonText: '确定',
            type: 'error'
        })
    } else {
        Vue.prototype.$confirm(`接口通讯失败，请尝试刷新页面或者重新登录。如果还是存在问题，请联系管理员\n[error]: ${error.message}`, '系统通知', {
            confirmButtonText: '确定',
            type: 'error'
        })
    }
});
export default service;