// 渠道类型
import * as type from "../mutation-types"
const state = {
  data:window.localStorage.getItem("staff") || ""
}

const getters = {
  getStaff:state=>state.data
}

const mutations = {
  [type.USER_OUT_LOGIN](state){
    state.data = null
  }
}

const actions = {
  onUse(response){
    window.localStorage.setItem("staff",response)
  },
  outLogin({commit}){
    commit(type.USER_OUT_LOGIN)
    window.localStorage.removeItem("staff")
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}