import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import OSS from './plugins/oss'
import store from './store'
//引入请求
import request from "./api/index"//请求
//全局引用Element ui
import ElementUI from 'element-ui'; 
import 'element-ui/lib/theme-chalk/index.css';
//引入vueI18处理语言切换
import VueI18n from 'vue-i18n';
//引入element处理语言
import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import CN from "@/languages/Chinese"
// import EN from "@/languages/English"
//改变Element的主题颜色
import './assets/style/element-variables.scss'
//引入ECharts制作数据可视化
import * as echarts from 'echarts'
//封装函数，全局挂载到Vue示例上
import dataConversion from '@/plugins/dataConversion'
import copy from './plugins/copyText'
//导入条形码插件
import VueBarcode from 'vue-barcode'; 
//引入修改鼠标右键事件
import Contextmenu from "v-contextmenu-directive"
// 封装权限按钮函数，全局挂载到Vue实例上
import getPermissionButton from "@/plugins/getPermissionButton"

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.use(ElementUI,{size:"mini "});//elementUI默认大小为mini
Vue.use(VueI18n)
Vue.use(request)
Vue.use(dataConversion)
Vue.use(copy)
Vue.use(getPermissionButton)
Vue.use(Contextmenu)
Vue.component('barcode', VueBarcode)

//此处将CN中所有下划线替换为空格使用
let newCN = {}
for (var key in CN) {
  newCN[key] = key.replace(/_/g, ' ')
}
// console.log(newCN);
const i18n=new VueI18n({
  locale:localStorage.getItem("oms-local")||"zh",
  messages:{
    zh:{
      ...zhLocale,//elementui中文包
      ...CN
    },
    en:{
      ...enLocale,//elementui英文包
      ...newCN
    }
  },
  silentTranslationWarn: true
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
new Vue({
  i18n,
  router,
  // OSS,
  store,
  echarts,
  render: h => h(App)
}).$mount('#app')
