export default {
  //常规
  loading: "加载中",
  quantity: "数量",
  name: "名称",
  all: "全部",
  yes: "是",
  no: "否",
  or: "或",
  and: "与",
  in_all:"总共",
  Query: "查询",
  creation: "创建",
  Batchquery: "批量查询",
  delete: "删除",
  successfully_delete: "删除成功!",
  edit: "编辑",
  editUser: "编辑用户",
  confirm: "确认",
  print: "打印",
  today: "今日",
  yesterday: "昨日",
  Before_yesterday: "前日",
  printLog: "打印日志",
  check: "查看",
  Button: "按钮",
  revocation: "撤销",
  Query_content: "查询内容",
  Scanning_successful: "扫描成功",
  successful_operation: "操作成功!",
  The_query_is_successful: "查询成功",
  Delete_success: "删除成功！",
  operation_failed: "操作失败！",
  more: "更多",
  click_to_download: "点击下载",
  More_operations: "更多操作",
  More_Conditions: "更多条件",
  Displaystatus: "显示状态",
  According: "显示",
  NoAccording: "不显示",
  Please_select_display_status: "请选择显示状态",
  Path_is_None_Check_whether_the_address_is_correct:
    "地址为空，请查看地址输入是否正确!",
  Is_this_a_piece_of_content_sure_to_delete: "这是一段内容确定删除吗？",
  phone_number: "手机号",
  Please_enter_a_phone_number: "请输入手机号",
  SystemSettings: "系统设置",
  please_enter: "请输入",
  old_password: "旧密码",
  Please_enter_your_old_password: "请输入旧密码",
  new_password: "新密码",
  Please_enter_the_order_number: "请输入单号",
  Please_enter_the_search_criteria: "请输入查询条件",
  uploading: "上传",
  next_step: "下一步",
  last_step: "上一步",
  change_password: "修改密码",
  Mission_center: "任务中心",
  Drag_and_drop_upload: "拖拽上传",
  Successfully_copied_to_clipboard: "已成功复制到剪贴板",
  Unable_to_copy_to_clipboard: "无法复制到剪贴板",
  The_form_verification_fails_Please_modify_and_try_again:
    "表单校验失败，请修改后重试",
  The_selected_data_is_empty: "选中数据为空",
  The_added_data_is_empty: "添加的数据为空",
  systematic_notification: "系统通知",
  open_in_new_window: "新窗口打开",
  successfully_upload: "上传成功",
  Specify_export_order: "指定导出订单",
  Specified_export_goods: "指定导出商品",
  Export_task_Id: "导出任务ID",
  Derived_exception: "导出异常",
  View_Personal_details: "查看个人详情",
  not_modified: "未修改",
  save_as_PDF: "另存为PDF",
  To_use_system_printing_you_need_to_download_a_print_plugin:
    "使用系统打印需要下载打印插件",
  The_current_user_has_no_permission_Please_set_the_permission_and_log_in_again:
    "当前用户未设置权限，请设置权限后在重试登录",
  Using_the_specified_export_order_data_will_overwrite_the_list_of_selected_order_data:
    "使用指定导出订单数据将会覆盖列表选择订单数据",
  This_order_has_not_been_shipped_out_of_the_warehouse_and_cannot_be_reprinted:
    "该订单尚未出库，无法补印面单",
  Interface_communication_failed_Please_refresh_the_page_or_log_in_again_If_the_problem_persists_contact_the_administrator:
    "接口通讯失败，请刷新页面或重新登录。如果问题依旧，请联系管理员",
  Enter_the_client: "进入客户端",
  query_condition: "查询条件",
  Home: "首页",
  return: "返回",
  Please_upload: "请上传",
  The_upload_format_is: "上传的格式为",
  The_file_format_was_wrong_last_time: "上传文件格式有误",
  The_uploaded_file_exceeds_the_specified_size_Procedure:
    "上传文件超出规定大小，上传失败",

  //登录
  Chinese: "中文",
  English: "English",
  OMS_System: "OMS系统客户端",
  OMS_System_Admin: "OMS系统管理端",
  UserAccount: "用户账号",
  password: "密码",
  RememberPassword: "记住密码",
  ForgotPassword: "忘记密码",
  RegisterAccount: "注册账号",
  Register: "注册",
  login: "登录",
  Please_enter_a_user_account: "未输入用户账号",
  Please_enter_the_user_password: "未输入密码",
  Obtain_SMS_verification_code: "获取短信验证码",
  SignIn: "登录中",
  LoginSuccessful: "登录成功",
  language: "语言",
  LogOut: "退出登录",
  No_data_yet: "暂无数据",
  Account_manager_ID: "客户经理工号",
  message_authentication_code: "短信验证码",
  The_SMS_verification_code_has_been_sent_Procedure: "已发送短信验证码",
  authentication_code: "验证码",
  Sending_the_verification_code: "正在发送验证码",
  Can_exchange: "看不清？点击换一张",
  Export_successfully: "导出成功!",
  Export_task: "导出任务",
  print_out_task: "打印任务",
  Derivation_time: "导出时间",
  Derived_state: "导出状态",
  exporting: "正在导出",
  Export_task_name: "任务名称",
  download: "下载",
  download_successfully: "下载成功",
  Too_many_errors_Please_enter_the_verification_code_and_try_again:
    "错误次数过多，请输入验证码后重试",
  fopltal: "操作频繁，请稍后再试！",
  Business_license: "营业执照",
  Identity_card: "身份证",

  //菜单
  Menu_authorization: "菜单授权",
  goodsOnline: "在线商品", //菜单名称修改时注意
  menuID: "菜单ID",
  authorization: "授权",
  editRoleInfo: "编辑角色",
  createRoleInfo: "创建角色", //菜单名称修改时注意
  Role_ordering: "角色排序",
  UserCenter: "个人中心",
  workbench: "工作台", //菜单名称修改时注意
  announceList: "公告列表", //菜单名称修改时注意
  Processing_Center: "处理中心",
  Outbound: "出库",
  Order: "订单",
  pendingOrders: "待发货订单",
  Basic_configuration: "基础配置",
  BasicConfiguration: "基础配置", //菜单名称修改时注意
  basketList: "篮子列表",
  fixedSkuFee: "固定Sku费", //菜单名称修改时注意
  consumables: "耗材类型",
  log: "日志",
  logType: "日志类型",
  oprateLog: "操作日志",
  oprateInfo: "操作信息",
  Please_enter_a_keyword_to_search: "请输入关键字进行搜索",
  MenuRemark: "菜单备注",
  Please_select_whether_to_enable_it: "请选择是否启用",
  Please_select_menu_type: "请选择菜单类型",
  menu: "菜单",
  MenuManagement: "菜单管理",
  Please_enter_a_menu_name: "请输入菜单名称",
  Menu_name: "菜单名称",
  Please_add_the_menu: "请先添加菜单",
  The_same_name_menu_is_not_allowed: "不允许出现相同名称菜单！",
  The_data_is_incorrect_adding_failed: "数据有误，添加失败",
  viewWarehouseEntry: "查看入库单", //菜单名称修改时注意
  PDAStatus: "PDA状态",

  //字典管理
  parent_directory: "上级目录",
  Dictionary_management: "字典管理",
  explain: "说明",
  Please_enter_explain: "请输入说明",
  warning: "警告",
  cancel_authorization: "取消授权",
  Definitive_de_authorization: "确定取消授权?",

  //待发货订单
  platform: "平台",
  shop: "店铺",
  Please_select_shop: "请选择店铺",
  warehouse: "仓库",
  Warehouse_name: "仓库名称",
  storage_location: "库位",
  order_number: "订单号",
  transaction_number: "交易号",
  waybill_number: "运单号",
  logistics_channel: "物流渠道",
  search: "搜索",
  type: "类型",
  state: "状态",
  time: "时间",
  country: "国家",
  Please_enter_country: "请输入国家",
  oprate: "操作",
  operation_log: "操作日志",
  thumbnail: "缩略图",
  Bar_code_of_goods: "商品条码",
  stock_position: "仓位",
  Orders_in_the_fulfillment: "配货中订单",
  Pending_sorting_tasks: "待分拣任务",
  Sorting_tasks: "分拣任务中",
  Pending_shipment: "待发货",
  In_transit: "发货中",
  Irregularity_in_delivery: "配货异常",
  Abnormal_quantity_of_distribution: "配货异常数量",
  The_sorting_task_is_abnormal: "分拣任务异常",
  Shipment_exception: "发货异常",
  Abnormal_shipment_quantity: "发货异常数量",
  log_list: "日志列表",
  Document_ID: "单据ID",
  task_order_number: "任务单号",
  order_id: "订单ID",
  Operation_type: "操作类型",
  Operation_Description: "操作描述",
  Operator: "操作人",
  creation_time: "创建时间",
  No_delivery_basket: "暂无配货筐",
  No_sorting_basket: "暂无分拣篮子",
  time_left: "剩余",
  overdue: "逾期",
  days: "天",
  hours: "时",
  minutes: "分",
  copy: "复制",
  copu_successful: "复制成功",
  verify_the_time: "确认时间",
  Transfer_to_oms_time: "转入oms时间",
  sorting_time: "分拣时间",
  Please_tick_the_content_first: "请先勾选内容",
  start_date: "开始日期",
  end_date: "结束日期",
  to: "至",
  Whether_used: "是否已使用",
  Last_three_days: "最近三天",
  Last_week: "最近一周",
  Last_month: "最近一个月",
  Assign_a_distributor: "指定配货员",
  Please_appoint_a_distributor: "请选择指定配货员",
  positionTransfer: "移仓",
  shiftingParkingData: "移库",
  Old_storage_location: "旧库位",
  New_storage_location: "新库位",
  Transfer_quantity: "移库数量",
  Add_transfer_library: "添加移库",
  The_number_of_removable_warehouses: "可移库数量",
  Cannot_scan_the_same_database_location: "不能扫描同一库位",
  This_product_does_not_exist_in_this_repository_Please_scan_other_repositories:
    "该产品在该库位不存在库存,请扫描其他库位",

  //单品配货
  Single_product_distribution: "单品配货",
  singleProductDistribution: "单品配货", //菜单名称修改时注意
  tips: "温馨提示",
  Task_mode: "任务方式",
  Get_task: "获取任务",
  Warehouse_partition: "仓库分区",
  Distribution_box_scanning_box: "配货框扫描框",
  Distribution_basket_code: "配货筐编码",
  Barcode_scanning_box_of_goods: "商品条码扫描框",
  picture: "图片",
  Allocated_goods: "已配货",
  To_be_distributed: "待配货",
  stock: "库存",
  view_details: "查看详情",
  details: "详情",
  Logistics_channel: "物流渠道",
  Please_enter_customer_ID: "请输入客户ID",
  conveyor: "传送带",
  please_scan_the_distribution_basket: "请扫描配货筐",
  The_basket_does_not_exist: "该篮子不存在",
  Quantity_of_goods_allocated: "已配货数量",
  Quantity_sorted: "已分拣数量",
  Quantity_of_goods_to_be_allocated: "待配货数量",
  distributor: "配货人",
  Distribution_time: "配货时间",
  Total_quantity_to_be_allocated: "总待配货数量",
  abnormal_quantity: "异常数量",
  Distribution_start_time: "配货开始时间",
  Distribution_end_time: "配货结束时间",
  Bulk_allocation: "批量配货",
  Sorting_query: "分拣查询",
  Separate_sorting_and_shipping: "单独分拣发货",
  Total_sorting_quantity: "总分拣数量",
  Sorting_stage: "分拣阶段",
  Sorting_end_time: "分拣结束时间",
  sorter: "分拣人",
  reauthorize: "重新授权",
  Prepare_a_single_item_distribution_basket_in_the_current_warehouse:
    "准备一个当前仓库下的单品配货筐",
  Scan_the_distribution_basket: "扫描配货筐",
  Scanning_product_bar_code: "扫描商品条码",
  Click_the_quantity_of_allocated_goods_to_batch_allocate_goods:
    "点击已配货数量可批量配货",
  Scan_the_corresponding_warehouse_location_for_distribution:
    "扫描对应库位进行配货",
  Take_the_goods_that_have_been_allocated_and_scan_the_goods_directly_to_be_shipped:
    "将配货好的商品拿过来，直接扫描商品就可以进行发货",
  Click_the_shipped_quantity_to_ship_in_bulk: "点击已发货数量可进行批量发货",
  Click_on_the_selected_quantity_to_pick_in_bulk: "点击已挑拣数量可以批量挑拣",
  Scan_the_sorting_basket_head: "扫描分拣篮子头",
  Scan_all_distribution_baskets: "扫描所有配货筐",
  Place_in_the_corresponding_sorting_basket: "放入对应的分拣篮子中",
  Scan_the_sorting_basket: "扫描分拣篮子",
  Scan_all_products: "扫描全部商品",
  priority_in_delivery: "优先发货",
  Confirmation_marked_as_priority_shipment: "确认标记为优先发货",
  Click_on_the_shipped_quantity_to_scan_in_bulk: "点击已发货数量可以批量扫描",
  Carry_out_the_corresponding_weighing_photo_operation_to_deliver_the_goods:
    "执行对应称重拍照操作进行发货",

  //多品配货
  multiProductDistribution: "多品配货", //菜单名称修改时注意

  //单品发货
  singleItemDelivery: "单品发货", //菜单名称修改时注意
  Packaging_material: "包材",
  Order_information: "订单信息",
  orderInfo: "订单信息", //菜单名称修改时注意
  total: "合计",
  Shipper: "配货员",
  package_photo: "包裹照片",
  Package_material_information: "包材信息",
  Waybill_number: "运单号",
  Has_been_inspected: "已验货",
  pending_inspection: "待验货",
  abnormal_delivery: "发货异常",
  Photograph: "拍照",
  Enter_key_to_take_a_photo: "回车键拍照",
  Please_weigh_the_order: "请进行订单称重",
  Please_photos_the_order: "请进行订单拍照",
  Out_of_stock_successfully: "出库成功",
  The_order_number_cannot_be_empty: "输入的订单号不能为空！",
  submit: "提交",
  send_back: "退回",
  Flag_exception: "标记异常",
  weigh: "称重",
  Remaining_shipment_quantity: "剩余发货数量",
  Quantity_shipped: "已发货数量",
  Quantity_of_goods_to_be_shipped: "待发货数量",
  The_barcode_does_not_exist: "不存在该商品条码",
  Follow_these_steps_to_fill_in_the_standard_template_and_import_the_data:
    "请按照以下步骤填写标准模板并导入数据：",
  Header_Entry_order_information_In_the_template_fill_in_the_data_of_the_table_header_from_order_type_to_remarks_Each_row_represents_an_entry_orderentry_order:
    "1. 头程/入库单信息：在模板中，填写表头从 订单类型 到 备注 的数据，每一行代表一个入库单/头程单。",
  Box_product_information_In_the_template_fill_in_the_table_head_data_from_the_box_number_to_the_weight_KG_box_Each_row_represents_a_box_of_products:
    "2. 箱产品信息：在模板中，填写表头从 箱号 到 重量KG/箱 的数据，每一行代表一箱产品。",
  Please_ensure_that_each_item_is_filled_in_accurately_and_follow_the_example_after_downloading_the_standard_template:
    "请确保每一项数据都填写准确，并下载标准模板后按照示例填写。",
  The_weight_must_not_be_less_than_or_equal_to_0: "重量不得小于等于0",
  Exceeds_the_maximum_weight_please_re_enter: "超出最大重量，请重新输入",
  Unable_to_access_the_camera: "无法访问摄像头",
  Error_uploading_files_to_Alibaba_Cloud_Please_contact_the_administrator:
    "文件上传至阿里云出错请联系管理员",
  The_number_of_anomalies_entered_is_incorrect: "输入的异常数量有误",

  //多品分拣
  multiProductSorting: "多品分拣",
  The_sorting_basket_does_not_exist_or_is_already_occupied_Please_refresh_and_try_again:
    "该分拣篮子不存在或已被占用，请刷新后重试",
  Basket_state: "篮子状态",
  Please_Select_Basket_state: "请选择篮子状态",
  The_distribution_basket_does_not_exist: "该配货篮子不存在",
  Sort_the_basket_head: "分拣篮子头",
  Task_details: "任务详情",
  Distribution_basket_not_scanned: "未扫描配货筐",
  Remaining_sorting_orders: "剩余分拣订单",
  Quantity_of_remaining_sorting: "剩余分拣数量",
  Sorting_abnormal_quantity: "分拣异常数量",
  Sorting_start_time: "分拣开始时间",
  The_list_has_been_picked: "已挑拣列表",
  Pick_details: "挑拣详情",
  Sorting_number: "分拣编号",
  Quantity_picked: "已挑拣数量",
  Quantity_to_be_picked: "待挑拣数量",
  Bulk_pick_quantity: "批量挑拣数量",
  Allotment_quantity: "配货数量",
  number_of_pages: "页数",

  //多品发货
  Multi_product_delivery: "多品发货",
  multiProductDelivery: "多品发货", //菜单名称修改时注意
  IF_Need_to_take_pictures: "是否需要拍照",
  IF_Need_to_be_weighed: "是否需要称重",
  Priority_Shipping_Orders: "优先发货订单",
  Scanning_sorting_blue_head: "扫描分拣蓝子头",
  Number_of_current_product_types: "当前商品种类数量",
  kind: "种",
  indivual: "个",
  dispatcher: "配货员",
  Shipping_Basket: "分拣篮子",
  Preview_photo: "预览照片",
  reshoot: "重新拍摄",
  No_photos_yet: "暂无照片",
  determine: "确定",
  Sorting_error_No_basket_number: "分拣有误，无篮子编号",
  The_current_task_is_complete: "当前任务已完成",

  //称重出库
  Transaction_number_only: "仅交易号",
  Transaction_number_and_order_weight: "交易号和订单重量",
  Waybill_No_only: "仅运单号",
  Shipment_number_and_order_weight_only: "仅运单号和订单重量",
  Shipping_format: "发货格式",
  Freight_mode: "货运方式",
  Scan_delivery_information: "扫描配送信息",

  //篮子列表
  add_basket_head: "新增篮子头",
  save: "保存",
  Cancel: "取消",
  sequence: "序号",
  Please_enter_basket_header: "请输入篮子头",
  Add_basket: "新增篮子",
  Edit_basket: "编辑篮子",
  Basket_Type: "篮子类型",
  basket_head: "篮子头",
  basket_name: "篮子名字",
  Please_select_a_basket_type: "请选择篮子类型",
  Basket_description: "篮子描述",
  Please_enter_a_basket_description: "请输入篮子描述",
  sorting_basket: "分拣篮子",
  Allotment_basket: "配货篮子",
  Please_select_a_distribution_basket: "请选择配货篮子",
  Sorting_basket_number: "分拣篮子编号",
  Allocation_phase: "配货阶段",
  Allotment_basket_item: "配货篮子(单品)",
  quality_inspection_basket: "质检篮子",
  other_types: "其他类型",
  Operation_time: "操作时间",
  Can_only_contain_letters_and_numbers: "只能包含字母和数字",
  print_basket_number: "打印篮子编号",
  starting_number: "起始编号",
  end_number: "结束编号",
  The_label_specification: "标签规格",
  custom_specification: "自定义标签规格",
  operational_context: "操作内容",
  Basket_number: "篮子编号",
  Please_enter_the_basket_name: "请输入篮子编号",
  Bulk_allocation_quantity: "批量配货数量",
  available: "可用",
  occupy: "占用",
  Allocation_task_for_additional_packing_material_delivery:
    "额外包材发货的配货任务!",
  The_basket_needs_to_be_placed_in_the_special_packing_area_of_the_item:
    "需要将该篮子放置在单品特殊打包处",
  The_basket_needs_to_be_placed_in_the_special_packing_area_for_explosive_products:
    "需要将该篮子放置在爆款特殊打包处",
  Please_place_in_the_nearest_single_item_delivery_area:
    "配货完成!请放置到最近的单品发货区",
  Distribution_completed_Please_place_it_in_the_nearest_delivery_area:
    "配货完成!请放置到最近的爆款发货区",
  If_the_inspection_fails_contact_the_administrator:
    "面单检测失败，请联系管理员进行处理",
  Incorrect_ticket_address_Failed_to_read_the_ticket:
    "面单地址有误读取面单失败",
  Failed_to_parse_the_PDF_content: "面单内容损坏解析PDF内容失败",
  Failed_to_convert_PDF_to_JPG: "PDF转JPG失败",
  Failed_to_parse_the_JPG_content: "解析JPG内容失败",
  The_surface_bill_does_not_match_the_waybill: "面单与运单不匹配",

  //sku固定信息
  Charge_items: "收费类型",
  Add_currency_information: "新增收费信息",
  Edit_currency_information: "编辑收费信息",
  special_price: "特殊价格",
  default_price: "默认价格",
  discounted_price: "优惠价",
  Listing_fee: "上架费",
  order_processing_fee: "订单处理费",
  orderProcessingFee: "订单处理费", //菜单名称修改时注意
  Destruction_delisting_fee: "销毁下架费",
  Return_listing_fee: "退件上架费",
  currency: "货币",
  currency_Type: "货币类型",
  Please_select_currency_type: "请选择货币类型",
  Please_enter_SKU: "请输入SKU",
  Please_enter_Stock: "请输入仓库",
  Please_enter_a_range_fee: "请输入范围费用",
  Please_enter_the_number_of_ranges: "请输入范围数量",
  Please_select_Charge_items: "请选择收费类型",
  expense_breakdown: "费用明细",
  Order_Status: "订单状态",
  Empty_product_details_exist_Please_check_and_try_again:
    "存在空的商品明细，请检查后重试",

  //发货控制
  SKU: "SKU",
  Modified_by: "修改人",
  founder: "创建人",
  Please_enter_founder: "请输入创建人",
  Please_enter_Modified_by: "请输入修改人",
  tip: "提示",
  Confirm_before_deleting: "您确认要删除这一条数据吗，删除之后将无法恢复！",
  other: "其他",
  ProductID: "商品编号",
  batch_number: "批次号",
  Load_rate: "上架率",

  //菜单列表
  Menu_list: "菜单列表",
  menuList: "菜单列表", //菜单名称修改时注意
  add_Menu: "新增菜单",
  Batch_add_menu: "批量新增菜单",
  Edit_Menu: "编辑菜单",
  Menu_Name: "菜单中文名称",
  Menu_EnName: "菜单英文名称",
  Please_enter_the_Menu_Name: "请输入菜单中文名称",
  Please_enter_the_Menu_EnName: "请输入菜单英文名称",
  Please_enter_menu_sort: "请输入菜单排序",
  Menu_function: "菜单功能",
  Menu_Icon: "图标",
  catalogue: "目录",
  Menu_Path: "菜单地址",
  structure_configuration: "结构配置",
  Please_enter_the_Menu_Path: "请输入访问地址",
  Menu_Sort: "菜单排序",
  Menu_Code: "菜单编码",
  MenuPlease_enter_the_Menu_Code: "请输入菜单编码",
  MenuPlease_enter_the_Menu_address: "请输入菜单地址",
  Please_enter_Menu_Sort: "请输入排序",
  Menu_Name_Not_Null: "菜单中文名称不能为空",
  Menu_EnName_Not_Null: "菜单英文名称不能为空",
  Menu_Code_Not_Null: "菜单编码不能为空",
  Paren_Menu: "父级菜单",
  Son_Menu: "子级菜单",
  Please_Select_Paren_Menu: "请选择父级菜单",
  Please_Select_Son_Menu: "请选择子级菜单",
  Menu_Level: "菜单级别",
  Menu_creator: "菜单创建人",
  Menu_modifier: "菜单修改人",
  Menu_creation_time: "菜单创建时间",
  Menu_change_time: "菜单修改时间",
  Menu_Type: "菜单类型",
  Menu_Stair: "一级",
  Menu_Second: "二级",
  Menu_Three: "三级",
  Visible: "是否可见",
  Remark: "备注",
  RemarkInfo: "备注信息",
  Please_Input_Remark: "请输入备注",

  //角色列表
  roleInfo: "角色信息",
  sysRole: "角色列表",
  roleList: "角色列表", //菜单名称修改时注意
  Role_CnName: "角色中文名称",
  Role_EnName: "角色英文名称",
  Role_Code: "角色唯一编码",
  Role_IsStart: "是否启用",
  Add_Role: "新增角色",
  Edit_Role: "新增角色",
  Role_Name: "角色名称",
  Please_Input_Role_Name: "请输入角色名称",
  Please_Input_Role_CnName: "请输入中文角色名称",
  Please_Input_Role_EnName: "请输入中文角色名称",
  Please_Input_Role_Code: "请输入角色唯一编码",
  Reset: "重置",
  Role_Sort: "排序",
  Role_CnName_Not_Null: "角色中文名称不能为空",
  Role_EnName_Not_Null: "角色英文名称不能为空",
  Role_Code_Not_Null: "角色唯一编码不能为空",
  Accredit_Menu: "授权菜单",
  Accredit_Stock: "授权仓库",
  More: "更多",
  Editing_role: "编辑角色",
  Modifying_Role_Permissions: "修改角色权限",
  Role_describe: "角色描述",
  blocked_balances: "冻结余额",
  Amount_of_gift: "赠送金额",
  Adjustment_type: "调整类型",
  Adjustment_blocked_balances: "调整冻结余额",
  Adjustment_Amount_of_gift: "调整赠送金额",
  role_state: "角色状态",

  //用户列表
  usersList: "用户列表",
  usersInfo: "用户信息",
  userManagement: "用户管理",
  User_Code: "用户账号",
  User_Name: "用户名称",
  User_CnName: "角色中文名称",
  Owning_warehouse: "所属仓库",
  Role: "角色",
  RoleManagement: "角色管理",
  user_password: "用户密码",
  staff: "员工",
  staffManagement: "员工管理",
  dictionaries: "字典",
  region: "地区",
  stateManagement: "国家管理",
  User_Branch: "部门",
  User_Phone: "手机",
  User_Rank: "职称",
  User_Email: "邮箱",
  User_EntryDate: "入职日期",
  User_Status: "用户状态",
  commonUser: "普通用户",
  service: "客服",
  open: "开启",
  finance: "财务",
  salesman: "业务员",
  salesmanager: "销售经理",
  superuser: "超级用户",
  overseasagency: "海外代理",
  Please_select_User_Status: "请选择用户状态",
  Please_select_User_Role: "请选择用户角色",
  Please_select_User_Type: "请选择用户类型",
  Please_select_User_Branch: "请选择部门",
  Please_select_User_Sex: "请选择性别",
  Please_select_salesman: "请选择业务员",
  Please_select_service: "请选择客服",
  User_Type: "用户类型",
  User_Sex: "用户性别",
  man: "男",
  woman: "女",
  User_Emergency: "紧急联系电话",
  User_PassWord: "密码",
  User_confirmPassWord: "确认密码",
  User_IDNumber: "身份证",
  User_Address: "住址",
  User_Unit: "单位",
  warehouseList: "仓库列表", //菜单名称修改时注意
  transshipmentdepot: "中转仓库",
  Please_Input_User_Name: "请输入用户名称",
  Please_Input_transshipmentdepot: "请输入中转仓库",
  Please_select_transshipmentdepot: "请选择中转仓库",
  Please_select_stock: "请选择仓库",
  Please_Input_User_Code: "请输入用户编码",
  Please_Input_User_PassWord: "请输入密码",
  Please_Input_User_confirmPassWord: "请输入确认密码",
  Please_Input_User_Rank: "请输入职称",
  Please_Input_User_IDNumber: "请输入身份证",
  Please_Input_User_Emergency: "请输入紧急联系电话",
  Please_Input_User_Phone: "请输入手机号码",
  Please_enter_the_correct_mobile_number: "请输入正确的手机号码",
  Please_Input_User_Address: "请输入住址",
  Please_Input_User_Email: "请输入邮箱",
  Please_enter_the_correct_email_address: "请输入正确的邮箱",
  Please_Input_User_Unit: "请输入单位",
  Please_Input_User_Status: "请选择状态",
  Please_Input_User_Role: "请选择角色",
  Entered_passwords_differ: "两次输入的密码不一致",
  The_password_contains_less_than_characters: "密码长度不能小于6位",
  Tpntbco816dolnos: "密码需要由8-16位组成的字母、数字或符号组成！",
  The_password_must_contain_8_to_16_characters: "密码长度应为8-16位",
  Add_User: "新增用户",
  Please_Select: "请选择",
  Please_Select_Task_Name: "请选择任务名称",
  Please_Select_Order: "请选择订单",
  Please_select_the_business_condition: "请选择业务情况",
  Please_Select_Date: "请选择时间",
  Please_select_billing_properties: "请选择计费属性",
  Please_select_whether_it_can_be_edited: "请选择是否可编辑",
  Enabled: "启用",
  Close: "关闭",
  all_transshipmentdepot: "全中转仓库",
  all_warehouse: "全仓库",
  Please_select_a_type: "请选择类型",
  Please_select_content: "请选择内容",

  //部门
  department: "部门",
  Investment_promotion_department: "招商部",
  accounting_department: "财务部",
  customer_service_department: "客服部",
  management_department: "管理部",
  oversea_location: "海外仓",
  distribution: "分销",
  IT: "IT",
  Logistics_Department: "物流部",

  //员工列表
  staffList: "员工列表", //菜单名称修改时注意
  personal_details: "个人信息",
  contact_Information: "联系信息",
  Owning_Information: "所属信息",
  account_Information: "账号信息",
  upload_photo_as_avatar: "上传头像",
  staffInfo: "员工信息", //菜单名称修改时注意
  staff_name: "员工名称",
  staff_Code: "员工账号",
  staff_Status: "员工状态",
  staff_Role: "员工角色",
  staff_gender: "员工性别",
  staffType: "员工类型",
  staff_password: "员工密码",
  Laborial_Staff: "普通员工",
  Administrator: "管理员",
  Please_enter_the_employee_name: "请输入员工名称",
  Please_enter_your_employee_ID: "请输入员工账号",
  Please_select_member_type: "请选择员工类型",
  createStaff: "创建员工", //菜单名称修改时注意
  editStaff: "编辑员工",
  Please_select_member: "请选择员工",
  Please_select_an_employee_role: "请选择员工角色",

  //耗材类型
  Add_consumables_type: "新增耗材类型",
  Edit_consumables_type: "编辑耗材类型",
  Consumable_name: "耗材名称",
  Description_of_consumables: "耗材描述",
  Please_enter_the_consumable_name: "请输入耗材名称",
  Please_enter_a_description_of_the_consumable: "请输入耗材描述",
  type_name: "类型名称",
  Type_description: "类型描述",
  Change_the_time: "修改时间",

  //耗材管理
  Add_consumables: "新增耗材",
  Add_consumable_information: "新增耗材信息",
  Editing_Supplies_Information: "编辑耗材信息",
  Consumables_Code: "耗材编码",
  Consumable_size: "耗材尺寸",
  Number_of_consumables: "耗材数量",
  single_standard_price: "单个标准价格",
  long: "长",
  long_cm: "长(cm)",
  outer_box_long_cm_one: "外箱长(cm/件)",
  Receiving_outer_box_long_cm_one: "收货外箱长(cm/件)",
  please_enter_Receiving_outer_box_long_cm_one: "请输入收货外箱长(cm/件)",
  width: "宽",
  width_cm: "宽(cm)",
  outer_box_width_cm_one: "外箱宽(cm/件)",
  Receiving_outer_box_width_cm_one: "收货外箱宽(cm/件)",
  please_enter_Receiving_outer_box_width_cm_one: "请输入收货外箱宽(cm/件)",
  Received_weight_KG_one: "收货重量(KG/个)",
  Received_weight_KG: "收货重量",
  please_enter_Received_weight_KG_one: "请输入收货重量(KG/个)",
  height: "高",
  height_cm: "高(cm)",
  outer_box_height_cm_one: "外箱高(cm/件)",
  Receiving_outer_box_height_cm_one: "收货外箱高(cm/件)",
  please_enter_Receiving_outer_box_height_cm_one: "请输入收货外箱高(cm/件)",
  Please_enter_the_consumable_code: "请输入耗材编码",
  Please_enter_length_width_and_height: "请输入长宽高尺寸",
  Please_enter_the_quantity_of_consumables: "请输入耗材数量",
  Please_enter_a_single_standard_price: "请输入单个标准价格",
  Please_select_a_warehouse: "请选择仓库",
  Please_enter_warehouse: "请输入仓库",
  Please_select_a_consumable_type: "请选择耗材类型",
  Consumable_Type: "耗材类型",
  Billing_way: "计费方式",
  Stores_operation_accounting_attributes: "存储操作计费属性",
  Precedence_Billing_way: "优先计费方式",
  Please_select_Precedence_Billing_way: "请选择优先计费方式",
  The_number_of_the_first_charge: "首件计费件数",
  please_enter_The_number_of_the_first_charge: "请输入首件计费件数",
  Please_select_Billing_way: "请选择计费方式",
  Volume_weight_value: "体积重值",
  Please_enter_volume_weight_value: "请输入体积重值",
  Please_enter_the_case_number: "请输入箱号",
  The_case_number_does_not_exist: "不存在该箱号!",
  The_sku_does_not_exist: "不存在该SKU",
  Save_receipt: "保存收货",
  Save_receipt_succeed: "保存收货成功",
  Please_stock_the_goods_at_the_specified_distribution_warehouse:
    "请在指定的配货库位配货",
  putaway: "上架",
  Inventory_batch_number: "库存批次号",
  unfinished: "未完成",
  completed: "已完成",
  Currently_no_floor_is_added_to_the_warehouse_Please_perform_this_operation_after_adding_a_floor:
    "当前仓库未添加楼层，请添加楼层后在进行此操作",
  If_the_selected_data_exists_the_selected_data_is_processed_preferentially_for_batch_processing:
    "若存在勾选数据，则优先处理勾选数据进行批量处理",
  If_the_selected_data_is_not_selected_manually_fill_in_the_box_number_range_for_batch_processing:
    "未勾选数据时则手动填写箱号区间进行批量处理",

  //一件待发费设置
  Momentumfee: "一件待发费设置",
  export: "导出",
  Export_the_import_and_export_details: "导出出入库明细",
  entryAndExitDetails: "出入库明细",
  add: "添加",
  Batch_add: "批量添加",
  Batch_delete: "批量删除",
  date: "日期",
  The_longest_edge: "最长边",
  Long_side: "次长边",
  The_shortest_edge: "最短边",
  weight: "重量",
  Start_weight: "起始重量",
  End_weight: "截止重量",
  weight_KG: "重量(KG)",
  number: "件数",
  SumThreeSides: "三边长总和",
  The_sum_of_the_three_sides_cannot_be_negative: "三边长总和不能为负数",
  First_packing_material_cost: "首包材费用",
  Three_sides_length: "三边和",
  Start_hree_sides_length: "起始三边长",
  End_hree_sides_length: "截止三边长",
  Three_sides_length_CM: "三边和(CM)",
  actual_SumThreeSides: "实际三边长总和",
  Continued_SKU_packaging_costs: "续SKU包材费用",
  The_number_of_continuations_is_charged: "续件计费件数",
  Please_enter_the_number_of_continuation_charges: "请输入续件计费件数",
  cost_a: "费用/个",
  cost_one_day_cube: "费用(天/立方)",
  cube: "立方",
  Start_cube: "起始立方",
  End_cube: "截止立方",
  Extra_charge: "续SKU操作费",
  One_charge: "首SKU操作费",
  One_Packaging_material: "首SKU包材费",
  Extra_Packaging_material: "续SKU包材费",
  Currency_type: "币种",
  Packaging_material_cost: "包材费",
  packagingMaterialManagement: "包材管理",
  Please_enter_Currency_type: "请输入币种",
  Please_select_Currency_type: "请选择币种",
  Please_enter_The_longest_edge: "请输入最长边",
  Please_enter_Long_side: "请输入次长边",
  Please_enter_The_shortest_edge: "请输入最短边",
  Please_enter_weight: "请输入重量",
  Please_enter_quantity: "请输入数量",
  Please_enter_SumThreeSides: "请输入三边长总和",
  Please_enter_cost_a: "请输入三边长总和",
  Please_enter_cost: "请输入费用",
  Please_enter_Extra_charge: "请输入续SKU操作费",
  Please_enter_One_charge: "请输入首SKU操作费",
  Please_enter_number: "请输入件数",
  Please_enter_One_Packaging_material: "请输入首SKU包材费",
  Please_enter_Extra_Packaging_material: "请输入续SKU包材费",
  Cancel_the_editor: "取消编辑",
  Cancel_the_add: "取消添加",
  Failed_to_start_the_camera_Please_check_whether_the_camera_is_available_Or_turn_on_the_camera_head:
    "摄像头开启失败，请检查摄像头是否可用！或者打开摄影头",
  This_operation_cannot_be_undone_Do_you_want_to_continue:
    "该操作将无法撤回，是否继续？",
  size: "尺寸",
  Please_select_size: "请选择尺寸",
  Please_enter_size: "请输入尺寸",
  Three_high: "长宽高",
  packaging: "打包方式",
  The_whole_pack: "整单打包",
  Pack_by_sku: "按SKU打包",
  Create_a_warehouse_entry: "创建入库单",
  Separate_product_packaging: "单独商品打包",
  editCost: "编辑费用", //菜单名称修改时注意

  //扫描拍照
  outbound_pictures: "出库拍照",
  photo_list: "拍照列表",
  scan_pictures: "扫描拍照",
  scanning_waybill_number: "扫描运单号",
  The_operation_fails_Check_whether_the_input_of_the_waybill_number_is_correct:
    "操作失败，请查看运单号输入是否正确",

  //拍照列表
  Review_images: "查看图片",
  Operation_of: "操作人",
  start_time: "开始时间",
  End_days: "结束天数",
  Please_enter_the_start_time: "请输入开始时间",
  Please_enter_the_end_time: "请输入结束时间",
  The_input_start_time_cannot_be_negative: "输入的开始时间不能为负数",
  The_end_time_cannot_be_negative: "输入的结束时间不能为负数",
  The_start_time_cannot_be_later_than_the_end_time:
    "开始时间不允许大于结束时间",
  The_end_time_cannot_be_later_than_the_start_time:
    "结束时间不允许小于开始时间",
  end_time: "结束时间",

  //公告
  Bulletin: "公告",
  BulletinManagement: "公告管理",
  Bulletin_Type: "公告类型",
  Bulletin_Name: "公告名称",
  This_window_asks_if_you_want_to_leave: "此窗口询问您是否要离开",
  The_information_you_enter_may_not_be_saved: "您输入的信息可能不会被保存",
  New_announcement: "新增公告",
  announcementState: "公告状态",

  //字典列表
  Adding_dictionary_Categories: "添加字典分类",
  DictionaryList: "字典列表",
  dictionaryList: "字典列表", //菜单名称修改时注意
  EditDictionary: "编辑字典",
  Please_enter_the_dictionary_name: "请输入字典名称",
  Name_of_dictionary: "字典名称",
  Chinese_name: "中文名称",
  English_name: "英文名称",
  Goods_Chinese_name: "商品中文名称",
  Goods_English_name: "商品英文名称",
  setpoint: "设定值",
  Please_enter_Chinese_name: "请输入中文名称",
  Please_enter_English_name: "请输入英文名称",
  Please_enter_setpoint: "请输入设定值",
  Add_dictionary: "添加字典",
  Batch_Add_dictionary: "批量添加字典",
  value: "值",
  Please_add_the_dictionary_first: "请先添加字典",
  Chinese_name_English_name_does_not_allow_null_value:
    "中文名称/英文名称不允许出现空值",
  The_value_cannot_be_modified_after_it_is_set_Please_confirm_and_add_it_after_completion:
    "值设定后将无法修改，请确认完成后在进行添加!",
  Batch_Add_Parent_Directory: "批量添加上级目录",

  //工作台
  recently: "近",
  day: "天",
  Warehouse_capacity: "仓库库容",
  Warehouse_capacity_data: "仓库库容数据",
  Service_storage_capacity: "使用库容",
  Storage_capacity_in_transit: "在途库容",
  Residual_storage_capacity: "剩余库容",
  Use_of_space: "使用仓位",
  Available_space: "可用仓位",
  financial_data: "财务数据",
  all_costs: "所有费用",
  storage_charges: "仓租",
  Removal_fee: "下架费",
  Returnable_shelving_fee: "退件上架费",
  other_expenses: "其他费用",
  Order_data: "订单数据",
  Orderto_be_supplied: "待配货订单",
  Order_In_transit: "发货中订单",
  Orderto_shipped: "已发货订单",
  Nearly_a_day: "近一天",
  Last_seven_days: "近七天",
  The_last_thirty_days: "近三十天",
  Nearly_ninety_days: "近九十天",
  Customer_details: "客户明细",
  TC_data: "头程数据",
  TC_wait_for_receiving: "头程待收货",
  Transfer_Warehouse_Receipt: "中转仓收货",
  Transit_warehouse_receiving_time: "中转仓收货时间",
  in_transit: "运输中",
  The_Last_Leg_Received: "尾程已收货",
  finish: "完成",
  Warehouse_entry_data: "入库单数据",
  Transit_warehouse_to_receive_goods: "中转仓待收货",
  The_last_leg_to_be_received: "尾程待收货",
  To_be_inspected: "待质检",
  To_be_put_on_the_shelf: "待上架",
  Return_data: "退件数据",

  //客户列表
  Customer_ID: "客户ID",
  ClientManagement: "客户管理",
  Customer: "客户",
  spread: "展开",
  pack_up: "收起",
  Put_away_the_menu: "收起菜单",
  Customer_List: "客户列表",
  clientList: "客户列表", //菜单名称修改时注意
  advanced_query: "高级查询",
  Customer_account_number: "客户账号",
  Please_enter_account_number: "请输入客户账号",
  Customer_account_name: "客户姓名",
  Please_enter_account_name: "请输入客户姓名",
  Customer_account_state: "客户状态",
  Please_enter_account_state: "请输入客户状态",
  unverified: "未认证",
  approve: "审批通过",
  Approval_failure: "审批失败",
  forbidden: "禁用",
  audit: "审核中",
  Contract_to_be_signed: "待签合同",
  cell_phone_number: "手机号码",
  Please_enter_cell_phone_number: "请输入手机号码",
  Please_select_cell_phone_number: "请选择客户状态",
  Please_select_Customer: "请选择客户",
  company_name: "公司",
  Please_enter_company_name: "请输入公司名称",
  registration_time: "注册时间",
  clearing_form: "结算方式",
  Please_select_clearing_form: "请选择结算方式",
  Starting_amount: "开始金额",
  Closing_amount: "结束金额",
  prejunction: "预结",
  monthly_balance: "月结",
  balance_outstanding: "余额",
  Please_enter_balance_outstanding: "请输入余额",
  Please_enter_contact_information: "请输入联系方式",
  Please_select_balance_outstanding: "请选择余额",
  Please_select_the_shipping_to_transit_bin_method: "请选择运输到中转仓方式",
  have: "有",
  without: "无",
  Add_customer: "添加客户",
  Batch_upload: "批量上传",
  reset_passwords: "重置密码",
  Limit_adjustment: "调整额度",
  allot_salesman: "分配业务员",
  allot_service: "分配客服",
  Customer_Name: "客户名称",
  subordinate_companies: "所属公司",
  contact_information: "联系方式",
  account_frozen: "冻结金额",
  line_of_credit: "信用额度",
  No_currency_available: "暂无币种",
  Set_currency: "设置币种",
  account_type: "账户类型",
  account_state: "账户状态",
  Merchant_name: "商户名",
  Merchant_Number: "商户号",
  Corporate_name: "法人姓名",
  Corporate_Identity_Card: "法人身份证",
  Corporate_phone_number: "法人手机号",
  Business_license_registration_address: "营业执照注册地址",
  business_address: "办公地址",
  Unified_social_credit_code: "统一社会信用代码",
  user_role: "用户角色",
  service_team: "服务团队",
  Distribution_service_team: "分配服务团队",
  Please_select_a_customer: "请选择一个客户",
  available_balance: "可用余额",
  Audit_customer: "审核客户",
  Confirm_to_audit_the_customer: "确认审核该客户",
  Please_enter_the_customer_mobile_number: "请输入客户手机号",
  New_account_login_will_overwrite_the_data_of_the_original_account_whether_to_continue:
    "新账号登录将会覆盖原有账号的数据，是否继续?",

  //客户信息
  client_info: "客户信息",
  clientInfo: "客户信息", //菜单名称修改时注意
  createClient: "创建客户", //菜单名称修改时注意
  editClient: "编辑客户", //菜单名称修改时注意
  basic_information: "基本信息",
  address_message: "地址信息",
  QQ_WeChat: "QQ/微信",
  oblast_province: "州/省",
  province: "省",
  district: "区",
  Please_enter_oblast: "请输入州",
  city: "城市",
  Please_enter_city: "请输入城市",
  location: "地址",
  shipping_address: "收货地址",
  Alternate_delivery_address: "备用收货地址",
  Please_enter_location: "请输入地址",
  financial_information: "财务信息",
  customer_code: "客户编码",
  Customer_mailbox: "客户邮箱",
  Customer_mobile_phone_number: "客户手机号",
  customer_company: "客户公司",
  Superior_account: "上级账号",
  service_conditions: "业务情况",
  underway: "进行中",
  Terminated: "已终止",
  delivery_method: "交付方式",
  assigner: "分配人",
  operating_log: "操作日志",
  auditor: "审核人",
  audit_time: "审核时间",
  Identifying_people: "确认人",
  acknowledging_time: "确认时间",
  Whether_to_send_back_ERP: "是否回传ERP",
  Return_time: "回传时间",
  Return_result: "回传结果",
  Account_top_up_details: "账户充值明细",
  accountTopUpDetails: "账户充值明细", //菜单名称修改时注意
  Recharge_method: "充值方式",
  Storage_query: "库存查询",
  storageQuery: "库存查询",
  account_recharge: "账户充值",
  accountRecharge: "账户充值", //菜单名称修改时注意
  online_trading: "在线交易",
  Online_payment: "在线付款",
  money: "金额",
  Please_enter_the_money: "请输入金额",
  Recharge_type: "充值类型",

  //温馨提示
  The_page_you_visited_does_not_exist_Please_check_whether_the_network_connection_is_normal_or_check_whether_the_address_input_is_correct:
    "您访问的页面不存在，请查看网络连接是否正常，或查看地址输入是否正确!",
  The_orders_that_have_been_shipped_and_failed_to_be_shipped_can_be_scanned_and_reprinted_here_Orders_that_have_been_shipped_can_be_reprinted_here_Note_The_order_must_be_the_order_placed_in_your_task_list:
    "已经发货扫描完成出货失败的订单，可以在此补印出货；已经出货的订单可以在此重新补印面单。（注：订单必须是您任务单下的订单）",
  Confirm_the_mark_is_abnormal: "确定标记异常？",
  operation_procedure: "操作流程",
  Operating_Instructions: "操作说明",

  //公告列表
  general: "通用",
  system_wide: "全系统",
  managementSide: "管理端",
  clientSide: "客户端",
  Announcement_title: "公告标题",
  Announcement_content: "公告内容",
  Announcement_Number: "公告编号",
  Please_enter_Announcement_title: "请输入公告标题",
  Please_enter_Announcement_Number: "请输入公告编号",
  Please_enter_Announcement_content: "请输入公告内容",
  Please_select_Bulletin_Type: "请选择公告类型",

  //仓储订单列表
  Inventory_order_list: "仓储订单列表",
  inventoryOrderList: "仓储订单列表", //菜单名称修改时注意
  warehouse_order_management: "仓储订单管理",
  logistics: "物流",
  logistics_status: "物流状态",
  Please_select_Logistics_channel: "请选择物流渠道",
  Platform_state: "平台状态",
  Please_select_Platform_state: "请选择平台状态",
  Please_enter_order_number: "请输入订单号",
  Please_enter_waybill_number: "请输入运单号",
  Please_enter_transaction_number: "请输入交易号",
  Priority_delivery: "优先交付",
  Priority_delivery_order: "优先交付订单",
  Non_priority_delivery_orders: "非优先交付订单",
  Platform_setting_priority_delivery: "平台设置优先交付",
  Manually_set_priority_delivery: "手动设置优先交付",
  Please_select_Priority_delivery: "请选择是否优先交付",
  shipping_method: "送货方式",
  Please_select_shipping_method: "请选择投递方式",
  Please_select_logistics_status: "请选择物流状态",
  Please_select_platform: "请选择平台",
  time_of_payment: "付款时间",
  Please_select_time_of_payment: "请选择付款时间",
  To_be_reviewed: "待审核",
  drop_shipping: "待发货",
  Being_distributed: "配货中",
  shipped: "已发货",
  have_been_voided: "已作废",
  Get_the_tracking_number: "获取运单号",
  Set_up_order_package_materials: "设置订单包材",
  Forward_delivery: "转入待发货",
  cancellation: "作废",
  activate: "激活",
  Get_order: "获取订单",
  Get_order_time: "获取订单时间",
  Upload_sheet: "上传面单",
  processing_batch: "批处理",
  Return_for_review: "退回待审核",
  Print_sheet: "打印面单",
  Printing_sheet: "打印面单中...",
  create_order: "创建订单",
  createOrder: "创建订单", //菜单名称修改时注意
  Batch_create_order: "批量创建订单",
  stores_expired: "个店铺授权过期",
  Push_or_not: "是否推送",
  Please_select_whether_to_push: "请选择是否推送",
  Please_select_order_status: "请选择订单状态",
  Platform_logistics_channel: "平台物流渠道",
  master_bill_number: "主单号",
  WMS_status: "WMS状态",
  destination_country: "目的国家",
  Platform_remarks: "平台备注",
  Warehouse_note: "仓库备注",
  Delivery_of_abnormal_information: "交运异常信息",
  Remaining_delivery_time: "剩余发货时间",
  order_amount: "订单金额",
  primitive_money: "原始货币",
  Order_profit: "订单利润",
  profit_margin: "利润率",
  delivery_time: "出库时间",
  Deduct_time: "扣费时间",
  Save_the_current_search_conditions: "保存当前查询条件",
  No_data_is_selected: "未选择任何数据!",
  No_customer_has_been_selected: "未选择任何客户!",
  The_order_was_cancelled_successfully: "订单作废成功!",
  Order_activation_success: "订单激活成功!",
  Orders_have_been_prioritized_for_delivery: "订单已设置优先交付!",
  OrderInfo: "订单详情",
  Modify_remarks: "修改备注",
  Quick_query_condition: "快捷查询条件",
  Marked_delivery: "标记发货",
  Please_select_a_piece_of_data: "请选择一条数据",
  Marked_delivery_Succeed: "标记发货成功",
  Marked_delivery_lose: "标记发货失败",
  Systematic_item_list: "系统商品明细",
  not_available: "暂无",
  multiattribute: "多属性",
  multiattributeID: "多属性ID",
  Please_select_an_order_from_the_same_warehouse: "请选择同一仓库下的订单",
  Batch_replacement_of_SKUs: "批量替换SKU",
  replace_SKU: "替换SKU",
  delete_SKU: "删除SKU",
  add_sku: "添加SKU",
  Only_one_SKU_can_be_replaced: "仅支持单个SKU进行替换",
  Select_the_sku_that_you_want_to_replace: "选择需要替换的sku",
  Select_the_new_SKU: "选择新的SKU",
  Whether_the_product_has_a_barcode: "商品是否有贴商品条码",
  Duplicate_SKUs_appear: "出现重复的SKU",
  Printing: "正在打印...",
  Please_select_a_type_of_basket: "请选择一个类型的篮子",
  printPreview: "预览打印",
  designPrinting: "设计打印",
  Delete_an_order: "删除订单",
  Pairing_or_not: "是否配对",
  type_of_merchandize: "商品种类",
  Print_type: "打印类型",
  The_uploaded_file_format_is_incorrect: "上传文件格式错误",
  Currently_only_single_order_upload_is_supported: "暂时只支持单订单上传",
  Save_as_PDF: "保存为PDF",
  downloading: "正在下载",
  Confirm_submission: "是否确认提交?",
  quantity_of_order: "订单数量",
  hour: "时",
  The_task_can_be_obtained_directly: "可直接获取任务",
  You_need_to_scan_the_distribution_basket_to_get_the_task:
    "需要扫描配货篮子才可以获取任务",
  When_the_task_type_is_multi_product_you_can_choose_whether_to_allocate_goods_by_floor:
    "当选择任务类型为多品才可以选择是否按楼层配货",
  Whether_to_scan_the_warehouse_location_when_distributing_goods:
    "配货时是否需要扫描库位",
  When_the_task_type_is_multi_product_the_matching_and_sending_function_cannot_be_enabled:
    "当选择任务类型为多品不可开启边配边发",
  presell: "预售",
  stockoutOrder: "缺货订单",
  No_out_of_stock_orders: "不缺货订单",
  Online_product_Pictures: "线上产品图片",
  After_the_warehouse_is_selected_the_corresponding_inventory_will_be_returned:"出库选择仓库后会回传对应库存",
  Transfer_the_tracking_number_to_obtain:"转入运单号获取中",
  The_tracking_number_exists:"存在运单号",
  No_waybill_number_exists:"不存在运单号",
  Whether_out_of_stock:"是否缺货",
  stockout:"缺货",
  Whether_the_waybill_number_exists:"是否存在运单号",
  Presence_platform_remarks:"存在平台备注",
  No_platform_remarks:"不存在平台备注",
  Presence_remark:"存在备注",
  No_remarks_exist:"不存在备注",
  Split_order:"拆分订单",
  Unsplit_order:"非拆分订单",
  Advance_order:"预售订单",
  Non_advance_order:"非预售订单",
  Matching_order:"配对订单",
  Already_paired_Order:"已配对订单",
  unpaired_Order:"未配对订单",
  Traffic_anomaly:"交运异常",
  A_traffic_exception_exists_Procedure:"存在交运异常",
  No_traffic_exception_exists:"不存在交运异常",
  allTask:"全部任务",
  todayTask:"今日任务",
  yesterdayTask:"昨日任务",
  New_quick_query:"新增快捷查询",
  Order_status_inquiry:"订单状态查询",
  The_tracking_number_is_being_obtained:"运单号获取中...",
  Submit_the_order_task:"提交的订单任务",
  Mixed_shipment_or_not:"是否混合发货",
  Order_task_submitted_today_00_00_00_18_00_00:"今日00:00:00-18:00:00提交的订单任务",
  Order_task_submitted_yesterday_00_00_00_23_59_59:"昨日00:00:00-23:59:59提交的订单任务",
  This_operation_will_permanently_delete_the_query_condition_Do_you_want_to_continue:"此操作将永久删除该查询条件, 是否继续?",
  After_obtaining_the_tracking_number_it_will_be_automatically_transferred_to_the_agent_for_delivery:"运单号获取成功后会自动转入待发货",

  //创建订单
  editOrder: "编辑订单",
  empty: "清空",
  consigner: "发件人",
  Select_sender: "选择发件人",
  Please_enter_consigner: "请输入发件人",
  Sender_information: "发件人信息",
  consigner_address: "发件人地址",
  Please_enter_consigner_address: "请输入发件人地址",
  consignee_information: "收件人信息",
  recipients: "收件人",
  recipientsList: "收件人列表", //菜单名称修改时注意
  Save_as_a_regular_recipient: "保存为常用收件人",
  Select_recipients: "选择收件人",
  Please_enter_recipients: "请输入收件人",
  postcode: "邮编",
  Please_enter_postcode: "请输入邮编",
  recipients_country: "收件人国家",
  Please_enter_recipients_country: "请输入收件人国家",
  Please_select_recipients_country: "请选择收件人国家",
  Please_select_recipients: "请选择收件人",
  type_of_shipping: "运输方式",
  Please_enter_type_of_shipping: "请输入运输方式",
  Please_select_type_of_shipping: "请选择运输方式",
  Alternate_recipient_address: "备用收件人地址",
  Please_enter_Alternate_recipient_address: "请输入备用收件人地址",
  commodity_information: "商品信息",
  Select_product: "选择产品",
  Save_until_reviewed: "保存至待审核",
  Submit_to_consignment: "提交至待发货",
  Batch_set_package_materials: "批量设置包材",
  Please_add_packing_material: "请添加包材",
  under: "下面的",
  Please_enter_the_tracking_number_information: "请输入单号货拉拉信息",
  Please_select_the_estimated_time_of_arrival: "请选择预计到仓时间",
  Only_a_single_recipient_can_be_selected: "仅允许选择单个收件人",
  Please_enter_a_combination_SKU: "请输入组合SKU",
  The_number_of_empty_combination_SKUs_exists_Check_and_try_again:
    "存在空的组合SKU数量，请检查后重试",
  Please_enter_the_combination_SKU_name: "请输入组合SKU名称",
  Click_to_select_the_row_data_doubleclick_to_select_the_row_data_and_confirm_the_current_selection_data:
    "单击选择该行数据，双击选择该行数据并确认当前选择数据！",
  tagged: "有标",
  No_mark: "无标",

  //头程管理
  first_leg: "头程",
  Head_process_management: "头程管理",
  FirstLegOrderManagement: "头程订单管理",
  firstLegOrderList: "头程订单列表", //菜单名称修改时注意
  firstBillingList: "头程账单列表", //菜单名称修改时注意
  First_billing_list: "头程账单列表",
  FirstLegOrderStatus: "头程单状态",
  FirstLegOrderNumber: "头程单号",
  First_order_stage: "头程订单阶段",
  Please_enter_FirstLegOrderNumber: "请输入头程单号",
  warehouse_entry_number: "入库单号",
  Please_enter_warehouse_entry_number: "请输入入库单号",
  createoriginReceivingInboundOrder: "创建头程入库订单", //菜单名称修改时注意
  editOriginReceivingInboundOrder: "编辑头程入库订单", //菜单名称修改时注意
  firstLegOrderId: "头程ID",
  draft: "草稿",
  audited: "已审核",
  abnormal: "异常",
  Warehouse_entry_stage: "入库单阶段",
  Warehouse_entry_description: "入库单描述",
  Batch_number_issued_by_three_warehouses: "三方仓下发批次号",
  Three_party_warehouse_PO_number: "三方仓PO单号",
  Storage_single_status: "入库单状态",
  destination: "目的地",
  Other_destinations: "其他目的地",
  Other_address: "其他地址",
  Other_address_contacts: "其他地址联系人",
  Other_address_Contact_number: "其他地址联系人号码",
  Estimated_time_of_arrival: "预计到仓时间",
  Channel_type: "渠道类型",
  Please_select_a_destination: "请选择目的地",
  Please_select_Channel_type: "请选择渠道类型",
  Self_delivered: "自送",
  Take_in: "揽收",
  total_quantity: "总数量",
  odd: "单号",
  push: "推送",
  Do_not_push: "不推送",
  Edit_cabinet_number: "编辑柜号",
  Cargo_pull_information: "货拉拉信息",
  odd_Cargo_pull_information: "单号/货拉拉信息",
  First_billing_stage: "头程账单阶段",
  First_pass_single_stage: "头程单阶段",
  Delivery_to_transit_warehouse: "运送到中转仓方式",
  Reserved_gross_weight: "预约总重量",
  Total_number_of_reservations: "预约总方数",
  Total_number_of_quantity: "预约总数量",
  Total_number_of_packages_arrived: "实际总件数",
  Actual_gross_weight: "实际总重量",
  Actual_total_number_of_squares: "实际总方数",
  Total_number_of_appointments: "预约总件数",
  Total_number_of_boxes_booked: "预约总箱数",
  Cost_per_cubic_meter_KG: "每立方/KG费用",
  expense_per_cubic_meter_KG: "每立方/KG成本",
  Additional_charge_per_cubic_meter_KG: "每立方/KG附加费用",
  Surcharge_cost_per_cubic_meter_KG: "每立方/KG附加费成本",
  container_number: "柜号",
  Please_select_a_stuffing_time: "请输入装柜时间",
  Customer_remarks: "客户备注",
  Customer_service_remarks: "客服备注",
  Financial_remarks: "财务备注",
  Abnormal_remarks: "异常备注",
  please_enter_Abnormal_remarks: "请输入异常备注",
  receiving_time: "收货时间",
  consignee: "收货人",
  Delivery_time: "中转收货时间",
  Estimated_time_to_transit_warehouse: "预计到中转仓时间",
  Destination_arrival_time: "目的地到货时间",
  submission_time: "提交时间",
  Create_a_Customer_ID: "创建客户ID",
  The_operation_failed_because_an_empty_box_exists_Please_modify_the_box_and_try_again:
    "操作失败，存在空的箱，请修改后重试!",
  The_operation_failed_because_there_is_an_empty_amount_of_incoming_storage_Please_modify_it_and_try_again:
    "操作失败，存在空的入库数量，请修改后重试!",
  viewOriginReceivingInboundOrder: "查看头程入库订单", //菜单名称修改时注意
  firstReceiptGoods: "头程收货", //菜单名称修改时注意
  goods_receiving_information: "收货信息",
  consignment_sheet: "收货清单",
  firstBillingDetails: "头程账单明细",
  billing_details: "账单明细",
  billingDetails: "账单明细", //菜单名称修改时注意
  Inventory_SKU_List: "库存SKU列表",
  inventorySKUIist: "库存SKU列表", //菜单名称修改时注意
  First_order_number: "头程订单号",
  Entry_number: "入库单单号",
  Financial_verification_time: "财务核实时间",
  Verify_collection_time: "核实收款时间",
  Total_freight: "总运费金额",
  freight_amount: "运费金额",
  Total_freight_cost: "总运费成本",
  gross_profit: "总利润",
  Gross_surcharge: "总附加费",
  Basic_query: "基本查询",
  Logistics_inquiry: "物流查询",
  logisticsInquiry: "物流查询", //菜单名称修改时注意
  Logistics_batch: "物流批次",
  Number_of_scans: "扫描数量",
  Successful_quantity: "成功数量",
  Number_of_failures: "失败数量",
  Remark_query: "备注查询",
  Time_query: "时间查询",
  Amount_inquiry: "金额查询",
  settlement_date: "结算时间",
  Statement_number: "结算单号",
  Supplier_settlement: "供应商结算",
  viewFirstBillingDetails: "查看头程账单明细",
  stuffing_time: "装柜时间",
  Changing_Customer_ID: "修改客户ID",
  Submit_initial_billing: "提交头程账单",
  Return_the_initial_bill: "退回头程账单",
  freight_inquiry: "货物查询",
  Starting_weight: "开始重量",
  Initial_number: "开始方数",
  End_square: "结束方数",
  Starting_number_of_packages: "开始件数",
  End_number_of_packages: "结束件数",
  Initial_quantity: "开始数量",
  End_quantity: "结束数量",
  Actual_quantity_arrived: "实际到货数量",
  Reservation_quantity: "预约数量",
  The_number_of_received_boxes_is_inconsistent_with_the_number_of_reserved_boxes_whether_to_continue_to_submit:
    "收货箱数与预约箱数不一致，是否继续提交",
  First_leg_deduction_fee: "头程扣费",
  Advance_payment: "头程付款",
  Transfer_warehouse_to_collect: "中转仓揽收",
  Shipping_to_overseas_warehouse: "运送至海外仓",
  Overseas_warehouse_delivery: "海外仓派送",
  There_are_mandatory_fields_in_the_received_information:
    "揽收信息存在未填必填项",
  There_are_mandatory_fields_in_the_channel_information:
    "渠道信息存在未填必填项",
  There_are_no_required_fields_in_the_delivery_information:
    "派送信息存在未填必填项",
  Please_select_a_delivery_type: "请选择派送类型",
  warehousing: "入仓",
  pick_up_in_a_store: "自提",
  delivery: "派送",
  Upload_credentials: "上传凭证",
  credentials: "凭证",
  Please_select_the_same_channel_type: "请选择相同的渠道类型",
  replacement_expense: "重置费用",
  Set_the_replacement_charge_according_to_the_head_charge:
    "按头程费用设置重置费用",
  Reset_the_current_order_cost_by_the_actual_number_of_square_feet:
    "按实际方数重置当前订单费用",
  Reset_the_current_order_charge_according_to_the_actual_order:
    " 按照订单实际方式重置当前订单费用",
  Order_different_goods_attributes_warehouse_transit_warehouse_billing_method_currency_customer_will_use_the_current_cost_Settings_to_calculate_the_cost:
    "订单不同货物属性，仓库 ，中转仓库，计费方式，币种，客户，都将使用当前费用设置计算费用。",
  Freight_cost_surcharge_cost_default_value_is_set_for_the_current_order_cost_corresponding_to_the_maximum_amount_cubic_KG:
    "运费成本，附加费成本，默认值为当前订单费用设置对应最大金额(立方/KG)。 ",
  Freight_cubic_meter_KG: "运费(立方/KG)",
  Additional_charge_cubic_meter_KG: "附加费(立方/KG)",
  freight_cost_cubic_meter_KG: "运费成本(立方/KG)",
  Surcharge_cost_cubic_meter_KG: "附加费成本(立方/KG)",
  The_content_is_not_filled_in: "存在未填写的内容",
  Please_scan_the_head_track_number: "请扫描头程单号",
  Please_scan_a_valid_header_tracking_number: "请扫描有效的头程单号",
  Attributes_of_goods_received: "收货货物属性",
  Commodity_property: "商品货物属性",
  Import_order: "导入订单",
  Click_Upload: "点击上传",
  Click_here_to_download_the_template: "点击此处下载模板",
  Download_template: "下载模板",
  DeliveryTime: "运输时间",
  Start_time_of_inspection: "开始质检时间",
  End_time_of_inspection: "结束质检时间",
  Shelf_start_time: "开始上架时间",
  End_shelf_time: "结束上架时间",
  Billing_information: "账单信息",
  evidence_of_payment: "付款凭证",

  //入库管理
  stockManagement: "入库管理",
  Warehouse_entry_list: "入库单列表",
  warehouseEntryList: "入库单列表", //菜单名称修改时注意
  warehouseReceiptList: "入库单收货列表", //菜单名称修改时注意
  over_time: "超时",
  Hit_shelves_on_time: "及时上架",
  Number_of_SKUs: "SKU数量",
  qualified_quantity: "合格数量",
  Quantity_of_reserved_storage: "预约入库数量",
  Number_of_reserved_storage_parties: "预约入库方数",
  time_efficiency: "时效",
  On_shelf_rate: "上架及时率",
  Whether_it_is_forced_into_transit: "是否强制到运输中",
  Store_entry_details: "入库单详情",
  Damaged_quantity: "破损数量",
  Miss_delivery: "漏发",
  Miss_delivery_quantity: "少发数量",
  wrongly_send: "错发",
  wrongly_send_quantity: "错发数量",
  Warehousing_to_receive_goods: "入库收货",
  warehousingReceiveGoods: "入库收货", //菜单名称修改时注意
  inventoryEntryListChecked: "入库单待质检列表", //菜单名称修改时注意
  inventoryEntryList: "入库单待上架列表", //菜单名称修改时注意
  Inbound_inspection: "入库质检",
  inboundInspection: "入库质检", //菜单名称修改时注意
  Quality_inspection_quantity: "质检数量",
  quality_testing: "质检",
  Batch_quality_inspection: "批量质检",
  Obtaining_quality_Inspection_Task: "获取质检任务",
  Generate_a_quality_check_task: "指派质检任务",
  Setting_priority_inspection: "设置优先质检",
  The_inspection_quantity_cannot_be_empty: "质检数量不能为空",
  Disabled_Excess_Check_quantity_cannot_be_greater_than_the_scheduled_check_quantity:
    "未开启超额质检时质检数量不能大于预约数量",
  Abnormal_quality_inspection_quantity: "质检数量异常",
  enter_the_qualified_quantity: "请输入合格数量",
  enter_the_breakage_quantity: "请输入破损数量",
  enter_the_lack_send_quantity: "请输入漏发数量",
  enter_the_wrongly_send_quantity: "请输入错发数量",
  enter_the_actual_weight: "请输入实际重量",
  enter_the_actual_length: "请输入实际长度",
  enter_the_actual_width: "请输入实际宽度",
  enter_the_actual_height: "请输入实际高度",
  length_CM: "长度(CM)",
  width_CM: "宽度(CM)",
  height_CM: "高度(CM)",
  Create_mis_sent_SKUs: "创建错发sku",
  The_number_of_missent_SKUs_cannot_be_0: "错发sku数量不能为0",
  Only_a_single_item_can_be_selected: "仅允许选择单件商品",
  Number_of_SKUs_sent_incorrectly: "错发SKU数量",
  The_inspection_of_the_sku_has_been_completed_Scan_other_SKUs:
    "该sku已经完成质检请扫描其他sku",
  The_inspection_Label_quantity_cannot_be_empty: "请填写贴标数量",
  The_inspection_Label_quantity_cannot_be_greater_than_the_qualified_quantity:
    "质检标签数量不能大于合格数量",
  Please_scan_the_receipt_number: "请扫描入库单号",
  Quality_inspector: "质检人",
  Quality_inspection_information: "质检信息",
  Product_real_information: "产品真实信息",
  printBoxLabel: "打印箱标",
  Print_head_entry_slip: "打印头程入库单",
  Print_storage_box_label: "打印入库箱标",
  Quantity_labeled: "已贴标数量",
  Quantity_inspected: "已质检数量",
  Printing_product_bar_code: "打印商品条码",

  //创建头程入库订单
  Please_enter_the_number_of_boxes: "请输入箱数",
  Order_types: "订单类型",
  entering_the_warehouse: "入库",
  FirstLegOrder: "头程单",
  InventoryOrder: "入库单",
  InventoryOrderType: "入库单类型",
  InventoryOrderID: "入库单ID",
  ThirdPartyInventoryOrder: "三方仓入库单",
  Receive_information: "揽收信息",
  Receiving_address: "揽收地址",
  transport_information: "运输信息",
  Arrival_information: "到仓信息",
  delivery_type: "交货方式",
  Push_order: "推送订单",
  Push_order_Type: "推送订单状态",
  Please_select_delivery_type: "请选择交货方式",
  Please_select_Order_types: "请选择订单类型",
  detailed_address: "详细地址",
  Please_enter_detailed_address: "请输入详细地址",
  Please_enter_phone: "请输入联系电话",
  Select_a_contact: "选择联系人",
  phone: "联系电话",
  warehouse_address: "仓库地址",
  Warehouse_information: "仓库信息",
  freight_information: "货运信息",
  Please_enter_mode_of_transportation: "请输入运输方式",
  Please_enter_warehouse_address: "请输入仓库地址",
  Please_select_Estimated_time_to_transit_warehouse: "请选择预计到中转仓时间",
  Please_select_an_estimated_time_to_warehouse: "请选择预计到仓库时间",
  Shipping_to_transit_warehouse_method: "运送到中转仓方式",
  General_container_number: "总箱号",
  cargo_list: "货物清单",
  cargoInfo: "货物信息",
  add_cargo_list: "添加货物清单",
  batch_add_cargo_list: "批量添加货物清单",
  total_value: "总价值",
  outer_box_length: "外箱长度",
  Please_enter_outer_box_length: "请输入外箱长度",
  outer_box_width: "外箱宽度",
  Please_enter_outer_box_width: "请输入外箱宽度",
  outer_box_height: "外箱高度",
  Please_enter_outer_box_height: "请输入外箱高度",
  Receiving_container_length: "收货外箱长度",
  Receiving_container_width: "收货外箱宽度",
  Receiving_container_height: "收货外箱高度",
  Received_weight: "收货重量",
  carton_size: "外箱尺寸",
  Please_select_whether_there_is_a_label: "请选择是否有标",
  Please_enter_the_customer_name: "请输入客户名称",
  box: "箱",
  boxNumber: "箱号",
  product_details: "商品明细",
  add_product_details: "添加商品明细",
  remove: "移除",
  product_image: "产品图片",
  Eng_Cn_Name: "中文品名",
  Please_enter_Eng_Cn_Name: "请输入中文品名",
  Eng_En_Name: "英文品名",
  Please_enter_Eng_En_Name: "请输入英文品名",
  unit_price: "单价",
  Please_enter_unit_price: "请输入单价",
  Is_there_a_mark: "是否有标",
  goodsCode: "商品条码",
  model: "型号",
  Please_enter_model: "请输入型号",
  material: "材质",
  stage: "阶段",
  product_code: "产品编码",
  Please_enter_material: "请输入材质",
  customs_code: "海关编码",
  Please_enter_customs_code: "请输入海关编码",
  brand: "品牌",
  Please_enter_brand: "请输入品牌",
  List_of_goods_not_selected_for_addition: "未选择添加的货物清单",
  There_is_an_empty_container_number_Please_fill_in_the_container_number_and_try_again:
    "存在空的箱号，请填写箱号后在重试!",
  Property_of_goods: "货物属性",
  Please_select_goods_attributes: "请选择货物属性",
  CollectInfo: "揽收信息",
  Repeated_SKUs_appear: "出现重复SKU",
  Save_to_draft: "保存至草稿",
  batch_processing: "批量处理",
  Quantity_in_storage: "入库数量",
  Dimensional_synchronization: "尺寸同步",
  The_batch_list_is_not_selected: "未选择批处理的货物清单",
  Total_storage_quantity: "总入库数量",
  Gross_cargo_weight: "货物总重量",
  Shipping_Information: "派送信息",
  Delivery_type: "派送类型",
  box_quantity: "箱数",
  Size_of_the_outer_box_received: "收货外箱尺寸",
  not_receive_the_goods: "未收货",
  received: "已收货",
  Take_pictures_of_receiving_goods: "收货图片",
  Receive_goods_in_one_box: "单箱收货",
  Double_receipt: "重复收货",
  Write_back_time: "回写时间",
  Write_back_result: "回写结果",
  Single_box_Only_one_box_can_be_selected: "单箱收货只允许选择单箱",
  The_receiving_list_has_empty_value_and_cannot_be_saved:
    "收货清单存在空值,保存失败",
  No_box_was_selected_to_save_the_receipt: "未选择任何保存收货的箱",
  Duplicate_receipt_is_detected_whether_to_duplicate_receipt:
    "检测到有重复收货，是否重复收货?",
  Whether_excess_inspection: "是否超额质检",
  Priority_inspection: "是否优先质检",
  Only_a_single_entry_ticket_can_be_selected: "仅允许选择单个入库单",
  The_box_number_already_exists: "箱号有误，请重新输入",
  Please_enter_the_box_number: "请输入箱号",
  The_inspection_of_all_the_current_container_numbers_has_been_completed:
    "当前箱号已全部质检完成",
  There_is_no_quality_inspection_data_for_the_current_container_number:
    "当前箱号暂无可质检数据，请重新扫描！",
  inspected: "已质检",
  Not_inspected: "未质检",
  Successful_receipt: "收货成功",
  Submit_and_print: "提交并打印",
  Print_head_list: "打印头程单",
  Header_list: "头程清单",
  printHeaderList: "打印头程清单", //菜单名称修改时注意
  printSubMitHeaderList: "打印提交头程清单", //菜单名称修改时注意
  First_shipping_mark: "头程唛头",
  printFirsShippingMark: "打印头程唛头", //菜单名称修改时注意
  printSubMitFirsShippingMark: "打印提交头程唛头", //菜单名称修改时注意
  First_single_box_label: "头程单箱唛",
  Warehouse_single_box_label: "入库单箱标",
  printFirstSingleBoxLabelAndWarehouseSingleBoxLabel:
    "打印头程单箱唛+入库单箱标", //菜单名称修改时注意
  printSubMitFirstSingleBoxLabelAndWarehouseSingleBoxLabel:
    "打印提交头程单箱唛+入库单箱标",
  Select_individual_order: "请选择单个订单",
  total_number_packages: "总件数",
  total_weight_KG: "总重量(KG)",
  Gross_cube_CBM: "总立方(CBM)",
  Gross_cube: "总立方",
  Export_data_priority: "导出数据优先级",
  Specified_exported_data: "指定导出数据",
  List_selection_data: "列表选择数据",
  List_filtering_data: "列表筛选数据",
  distributionManagement: "配货管理", //菜单名称修改时注意
  The_form_verification_failed_Please_check_and_try_again:
    "表单校验失败，请检查后重试",
  The_product_bar_code_does_not_exist_in_the_current_task_list_or_has_been_put_on_the_shelf:
    "当前任务单不存在此商品条码或已上架完成,请重新扫描！",

  //订单详情
  Forecast_error_message: "预报错信息",
  Take_pictures_out_of_the_warehouse: "出库拍照图",
  Online_product_details: "线上商品明细",
  Product_preview: "商品预览图",
  declared_value: "申报价值",
  Weight_KG_piece: "重量(KG/个)",
  Weight_KG_box: "重量(KG/箱)",
  Declared_value_USD_piece: "申报价值(USD/个)",
  Please_enter_Declared_value_USD_piece: "请输入申报价值(USD/个)",
  Please_enter_Weight_KG_piece: "请输入重量(KG/个)",

  //商品管理
  CommodityManagement: "商品管理",
  commodity: "商品",
  commodityName: "商品名称",
  SKUManagement: "SKU管理",
  inventorySKUManagement: "库存SKU管理",
  inventorySKU: "库存SKU",
  EditInventorySKU: "库存SKU",
  inventorySKUName: "库存SKU名称",
  inventorySKUList: "库存SKU列表", //菜单名称修改时注意
  inventorySKUImage: "库存SKU图片",
  bar_code: "条码",
  Commodity_billing_attribute: "商品计费属性",
  Editable_or_not: "是否可编辑",
  Combination_quantity: "组合数量",
  Add_stock_SKUs: "添加库存SKU",
  Please_enter_inventorySKU: "请输入库存SKU",
  Please_enter_bar_code: "请输入条码",
  product_Cn_name: "产品中文名称",
  Please_enter_product_Cn_name: "请输入产品中文名称",
  product_En_name: "产品英文名称",
  Please_enter_product_En_name: "请输入产品英文名称",
  initiate_mode: "启用状态",
  Please_select_initiate_mode: "请选择启用状态",
  inventory_status: "库存状态",
  Please_select_inventory_status: "请选择库存状态",
  Alarm_threshold_status: "预警阈值状态",
  Please_select_Alarm_threshold_status: "请选择预警阈值状态",
  Last_two_weeks: "最近两周",
  Last_two_month: "最近两月",
  Last_three_month: "最近三月",
  actual_weight_KG: "实际重量(KG)",
  Actual_Trilateral_sum_CM: "实际三边和(CM)",
  Actual_size_CM: "实际尺寸(CM)",
  physical_length: "实际长度",
  physical_width: "实际宽度",
  physical_height: "实际高度",
  size_CM: "尺寸(CM)",
  Actual_size: "实际尺寸",
  Please_enter_actual_weight: "请输入实际重量",
  Please_enter_actual_Trilateral_sum_CM: "请输入实际三边和(CM)",
  Please_enter_actual_weight_KG: "请输入实际重量(KG)",
  Please_enter_actual_size: "请输入实际尺寸",
  inventory_quantity: "库存总量",
  Total_occupied_inventory: "占用库存总量",
  Total_cubic_number_of_inventory: "库存总立方数",
  Total_unshipped_quantity: "未发货库存总量",
  Total_number_of_cubes_in_transit: "在途总立方数",
  Total_number_in_transit: "在途库存总量",
  Sales_Volume_7_14_30_60_90: "销量(7/14/30)",
  Sales_Volume_60_90: "销量(60/90)",
  Forecast_30_day_average_daily_sales: "预测30天日均销量",
  Number_of_warnings: "预警数量",
  purchase_price: "采购价",
  Please_select_SKU: "请选择SKU",
  portfolioSKUManagement: "组合SKU管理",
  portfolioSKUList: "组合SKU列表", //菜单名称修改时注意
  product_barcode: "商品条码",
  Please_enter_the_product_barcode: "请输入商品条码",
  Select_all_boxes:"选择所有箱",
  Please_enter_the_product_barcode_for_screening:"请输入商品条码进行筛选",
  combination_SKU_Chinese_name: "组合SKU中文名称",
  combination_SKU_English_name: "组合SKU英名称",
  Add_the_combined_SKU: "添加组合SKU",
  Combine_SKU_images: "组合SKU图片",
  combined_SKU: "组合SKU",
  Portfolio_inventory: "组合库存",
  goodsImage: "商品图片",
  viewPortfolioSKU: "查看组合SKU",
  Historical_operation_record: "历史操作记录",
  Edit_inventory_SKU_list: "编辑库存SKU列表",
  editInventorySkuList: "编辑库存SKU列表", //菜单名称修改时注意
  viewInventorySkuList: "查看库存SKU列表", //菜单名称修改时注意
  createInventorySkuList: "创建库存SKU列表", //菜单名称修改时注意
  Only_common_products_can_be_selected: "仅有普通产品才可多选",
  inventory_management: "库存管理",
  inventoryManagement: "库存管理",
  inventoryList: "库存列表", //菜单名称修改时注意
  product_material: "产品材质",
  Please_select_product_material: "请选择产品材质",
  Product_bar_code: "产品条码",
  Reference_number: "参考号",

  //创建商品
  createGoods: "创建商品", //菜单名称修改时注意
  product_information: "产品信息",
  Please_enter_product_information: "请输入产品信息",
  product_specification: "产品规格",
  Product_declaration: "产品申报",
  procurement_price: "采购价格",
  Please_enter_cost_price: "请输入成本价格",
  Please_enter_procurement_price: "请输入采购价格",
  volume_cm: "体积(cm³)",
  actual_volume: "实际体积",
  actual_volume_cm: "实际体积(cm³)",
  commercial_specification: "商品规格",
  Please_enter_commercial_specification: "请输入商品规格",
  Product_name_Chinese: "申报品名(中文)",
  Please_enter_Product_name: "请输入申报品名",
  Product_name_English: "申报品名(英文)",
  country_of_origin: "原产地",
  Please_enter_country_of_origin: "请输入原产地",
  Days_of_purchase: "采购天数",
  Please_enter_Days_of_purchase: "请输入采购天数",
  product_attribute: "产品属性",
  Please_select_product_properties: "请选择产品属性",
  inventory_warning: "库存预警",
  Early_warning_inventory: "预警库存",
  Days_of_warning: "预警天数",
  Please_enter_Early_warning_inventory: "请输入预警库存",
  Please_enter_Days_of_warning: "请输入预警天数",
  Calculating_early_warning_inventory: "计算预警库存方式",
  Automatic_calculation_of_early_warning_inventory: "自动计算预警库存",
  Alert_inventory_is_equal_to_alert_days_multiplied_by_daily_sales:
    "预警库存 = 预警天数 * 单天销量",
  Click_to_upload: "点击上传",
  Only_jpg_or_png_files_with_a_maximum_size_of_2048kb_can_be_uploaded:
    "只能上传jpg或png文件，且不超过2048kb",
  Please_upload_product_pictures: "请上传产品图片",
  Please_enter_dimensions_of_length_width_and_height_greater_than_zero:
    "请输入长宽高尺寸并且大于零",
  The_operation_failed__Please_check_the_content_and_submit_again:
    "操作失败，请检查填写内容后重新提交",
  editorialGoods: "编辑商品",
  viewGoods: "查看商品",
  Enter_SKU_and_bar_code_separated_by_commas_comma:
    "请输入SKU、条码，用逗号隔开",
  Please_enter_sku: "请输入sku",
  delete_in_batches_sku: "批量删除SKU",
  Select_the_sku_that_you_want_to_delete: "选择需要删除的sku",
  Adding_SKUs_in_bulk: "批量添加SKU",
  Edit_note: "编辑备注",
  select_sku: "选择SKU",
  Type_query: "类型查询",
  Export_goods: "导出商品",
  Export_incoming_and_outgoing_records: "导出出入库记录",
  product_list: "商品列表",
  product_list_sub_warehouse: "商品列表(分仓)",
  serial_Number: "编号",
  Create_distribution_control: "创建配货控制",
  Number_of_generated_tasks: "生成任务数量",
  create_task: "生成任务",
  Delivery_process_2_cannot_use_border_variation: "发货流程2无法使用边配变分",
  If_a_check_order_exists_during_task_generation_the_check_order_task_is_preferentially_generated:
    "生成任务时存在勾选订单则会优先生成勾选订单任务",

  //仓库列表
  warehouse_management: "仓库管理",
  warehouseOperation: "仓库操作",
  warehouse_Number: "仓库编号",
  Please_enter_warehouse_Number: "请输入仓库编号",
  Please_enter_warehouse_partition: "请输入仓库分区",
  goods_shelf: "货架",
  goods_shelf_info: "货架信息",
  Shelf_type: "货架类型",
  Please_enter_Shelf_type: "请输入货架类型",
  Shelf_layer: "货架层",
  Please_enter_Shelf_layer: "请输入货架层",
  Storage_rack: "货架列",
  Please_enter_Storage_rack: "请输入货架列",
  Shelf_size_length_width_and_height: "货架尺寸(长*宽*高)",
  Shelf_cube: "货架立方/m³",
  Please_enter_Shelf_cube: "请输入货架立方",
  Library_location_information: "库位信息",
  Storage_location_type: "库位类型",
  Please_enter_Storage_location_type: "请输入库位类型",
  SKU_storage_type: "SKU存放类型",
  Please_enter_goods_shelf: "请输入货架",
  Please_enter_Residual_storage_capacity: "请输入剩余库容",
  Please_enter_Storage_capacity_in_transit: "请输入在途库容",
  Please_enter_Service_storage_capacity: "请输入使用库容",
  Please_select_the_currency_of_charge: "请选择收费币种",
  Storage_location_SKU: "库位SKU",
  Please_enter_Storage_location_SKU: "请输入库位SKU",
  Initial_storage: "开始库容",
  End_storage: "结束库容",
  Add_partition: "添加分区",
  Edit_partition: "编辑分区",
  partition_number: "分区编号",
  Please_enter_partition: "请输入分区",
  Please_enter_partition_number: "请输入分区编号",
  Please_select_floor: "请选择楼层",
  partition: "分区",
  floor: "楼层",
  storey: "楼",
  Add_warehouse: "添加仓库",
  Edit_warehouse: "编辑仓库",
  Add_storage_location: "添加库位",
  contacts: "联系人",
  Please_enter_contacts: "请输入联系人",
  Warehouse_zip_code: "仓库邮编",
  Please_enter_Warehouse_zip_code: "请输入仓库邮编",
  Toll_currency: "收费币种",
  Please_enter_Toll_currency: "请输入收费币种",
  Warehouse_recipient: "仓库收件人",
  Recipient_phone_number: "收件人电话",
  Warehouse_capacity_m: "仓库库容/m³",
  Service_storage_capacity_m: "使用库容/m³",
  Storage_capacity_in_transit_m: "在途库容/m³",
  Residual_storage_capacity_m: "剩余库容/m³",
  Zoned_storage_m: "分区库容/m³",
  Storage_location_Storage_capacity_m: "库位库容/m³",
  Storage_capacity_SKU: "库容SKU",
  Bind_the_library_location_SKU: "绑定库位SKU",
  Pick_the_warehouse_location: "拣货库位",
  Shelf_coding: "货架编码",
  Please_enter_Shelf_coding: "请输入货架编码",
  Shelf_name: "货架名称",
  Please_enter_Shelf_name: "请输入货架名称",
  Shelf_length: "货架长",
  Please_enter_Shelf_length: "请输入货架长",
  Shelf_weight: "货架宽",
  Please_enter_Shelf_weight: "请输入货架宽",
  Shelf_height: "货架高",
  Please_enter_Shelf_height: "请输入货架高",
  single_face: "单面",
  two_sided: "双面",
  Warehouse_location_number: "库位编号",
  Edit_library_location: "编辑库位",
  Only_one_table_can_be_imported_simultaneously: "只允许同时导入一张表",
  Only_tables_can_be_imported: "只允许导入表",
  Only_select_tables_are_allowed: "仅允许选择表",
  Import_a_single_table_first: "请先进行单表导入",
  Unselected_formula: "未选择公式",
  Selection_result_table: "选择结果表",
  Please_select_table: "请选择表",
  Only_one_table_can_be_selected: "仅允许选择一张表",
  Manually_insert_data: "手动插入数据",
  The_current_table_is: "当前的表为",
  Trigger_name: "触发器名称",
  Please_enter_Trigger_name: "请输入触发器名称",
  libraryLocationList: "库位列表", //菜单名称修改时注意
  Back_libraryLocationList: "返回库位列表",
  have_been_used: "已使用",
  unused: "未使用",
  print_Library_location_number: "打印库位号",
  quantity_in_stock: "库存数量",
  Inventory_occupancy: "库存占用数量",
  Inventory_warning_quantity: "库存预警数量",

  //地区管理
  regional_management: "地区管理",
  countriesList: "国家列表", //菜单名称修改时注意
  Added_country: "添加国家",
  Bulk_add_countries: "批量添加国家",
  Please_select_country: "请选择国家",
  Please_select_Destination_warehouse: "请选择目的仓库",
  Destination_warehouse: "目的仓库",

  //地址管理
  recipientManagement: "收件人管理", //菜单名称修改时注意
  Please_enter_the_recipient_name: "请输入收件人姓名",
  Please_enter_the_recipient_phone_number: "请输入收件人电话",
  Recipient_name: "收件人名称",
  Receiver_Address: "收件人地址",
  Add_recipient: "添加收件人",
  Edit_recipient: "编辑收件人",
  Recipient_email_address: "收件人邮箱",
  Please_select_the_full_region: "请选择完整地区",
  Please_select_a_region: "请选择地区",

  //财务管理
  financialManagement: "财务管理",
  financialReports: "财务报告",
  cost: "费用",
  costmanagement: "费用管理",
  costType: "费用状态",
  costSetting: "费用设置",
  Client_editing: "客户编辑",
  AmazonBaiyiUS: "亚马逊百易科讯美国",
  amazonBaiyiUS: "亚马逊百易科讯美国", //菜单名称修改时注意
  month: "月份",
  turnover: "营业额",
  The_amount_of_the_platform_should_be_lent: "平台应放款金额",
  The_wrong_amount_should_be_lent: "应放款错误金额",
  have_already_settled: "已结算",
  consolidate_orders: "合并订单",
  refund: "退款",
  rebates: "部分退款",
  Refund_and_compensation: "退款赔付",
  compensate: "赔付",
  Unlent_amount: "未放款金额",
  deleteTable: "删除表",
  Confirm_deletion_table: "确认删除表",
  Recharge_time: "充值时间",
  Please_select_a_Recharge_time: "请选择充值时间",
  Add_top_up: "添加充值",
  exchange_rate: "汇率",
  Amount_actually_paid: "实际支付金额",
  Please_enter_the_Amount_actually_paid: "请输入实际支付金额",
  Currency_of_payment: "支付币种",
  Please_enter_the_Currency_of_payment: "请输入支付币种",
  The_currency_of_the_deposit: "充值币种",
  Please_enter_the_The_currency_of_the_deposit: "请输入充值币种",
  service_charge: "手续费",
  accounting_date: "到账时间",
  Please_select_a_accounting_date: "请选择到账时间",
  note: "备注",
  Top_up_number: "充值号",
  recharge_amount: "充值金额",
  Balance_before_recharge: "充值前余额",
  Balance_after_recharge: "充值后余额",
  The_charge_shall_not_be_greater_than_the_amount_of_the_deposit:
    "手续费不得大于充值金额",
  Recharge_information: "充值信息",
  Please_select_a_billing_time: "请选择结算时间",
  Please_enter_the_statement_number: "请输入结算单号",
  Please_select_a_recharge_type: "请选择充值类型",
  About_to_jump_to_the_recharge_page: "即将跳转充值页面",
  state_of_payment: "支付状态",
  Continue_to_pay: "继续支付",
  during_the_payment: "支付中",
  payment_failure: "支付失败",
  have_expired: "已过期",
  canceled: "已取消",
  Completed_transaction: "已完成交易",
  Uncompleted_transaction: "交易未完成",
  failure_of_transaction: "交易失败",
  Only_a_single_task_can_be_selected: "仅可选择单个任务",
  Please_select_a_basket: "请选择篮子",
  Sheet_address: "面单地址",
  Recommended_storage_location: "推荐库位",
  The_product_is_finished: "该商品已完成",

  //费用设置
  orderProcessingFeeSet: "订单处理费",
  Additional_cost: "添加费用",
  Additional_Max_cost: "添加最大费用",
  Add_order_processing_fee: "添加订单处理费",
  Additional_charge: "加收费用",
  Rate_number: "收费标准编号",
  type_of_cost: "费用类型",
  Please_select_type_of_cost: "请选择费用类型",
  Please_enter_Additional_charge: "请输入加收费用",
  Please_enter_Rate_number: "请输入收费标准编号",
  Out_of_range_first_SKU_operating_fee: "超范围首SKU操作费",
  Please_enter_Out_of_range_first_SKU_operating_fee: "请输入超范围首SKU操作费",
  Continued_SKU_operating_fee_out_of_range: "超范围续SKU操作费",
  Please_enter_Continued_SKU_operating_fee_out_of_range:
    "请输入超范围续SKU操作费",
  Cost_of_first_packing_material_out_of_range: "超范围首包材费用",
  Please_enter_Cost_of_first_packing_material_out_of_range:
    "请输入超范围首包材费用",
  SKU_packaging_costs_are_exceeded: "超范围续SKU包材费用",
  Please_enter_SKU_packaging_costs_are_exceeded: "请输入超范围续SKU包材费用",
  The_number_of_out_of_range_continuums_is_charged: "超范围续件计费件数",
  Please_enter_The_number_of_out_of_range_continuums_is_charged:
    "请输入超范围续件计费件数",
  The_input_add_fee_has_no_value_Please_check_and_try_again:
    "输入的添加费用存在空值，请检查后重试",
  The_charge_standard_number_cannot_be_repeated: "收费标准编号不能重复",
  Out_of_range_listing_fees: "超范围上架费",
  Out_of_range_Destruction_delisting_fee: "超范围销毁下架费",
  Out_of_range_Return_listing_fee: "超范围退件上架费",
  Three_sides_are_preferred: "优先三边长",
  Preferred_weight: "优先重量",
  packagingMaterialCost: "包材费", //菜单名称修改时注意
  Package_material_coding: "包材编码",
  Please_enter_Package_material_coding: "请输入包材编码",
  Packing_material_name: "包材名称",
  Please_enter_Packing_material_name: "请输入包材名称",
  Packing_material_length: "包材长度",
  Please_enter_Package_material_length: "请输入包材长度",
  Packing_material_width: "包材宽度",
  Please_enter_Package_material_width: "请输入包材宽度",
  Packing_material_height: "包材高度",
  Packing_material_Weight: "包材重量",
  Please_enter_Packing_material_height: "请输入包材高度",
  Packing_material_quantity: "包材数量",
  Please_enter_Packing_material_quantity: "请输入包材数量",
  Packing_material_type: "包材类型",
  Please_enter_Packing_material_type: "请输入包材类型",
  add_Packing_material: "添加包材",
  edit_Packing_material: "编辑包材",
  Batch_add_Packing_material: "批量添加包材",
  quantity_of_commodity: "商品数量",
  The_box_number_does_not_exist: "该箱号不存在",
  Packaging_material_picture: "包材图片",
  createCost: "创建费用", //菜单名称修改时注意
  Please_upload_picture_of_package_material: "请上传包材图片",
  expenseDeduction: "费用补扣", //菜单名称修改时注意
  Add_a_premium_deduction: "添加费用补扣",
  Expense_reimbursement_type: "费用补扣类型",
  Please_select_the_type_of_reimbursement: "请选择费用补扣类型",
  Please_select_packing_material: "请选择包材",
  Please_select_a_packaging_method: "请选择打包方式",
  Set_the_packaging_mode_in_batches: "批量设置打包方式",
  Please_enter_barcode_SKU_Chinese_product_name_English_product_name:
    "请输入SKU、商品条码、中文品名、英文品名进行查询",
  Please_enter_the_order_number_separated_by_line_breaks_or_commas:
    "请输入订单号，换行或逗号隔开",
  Packing_material_billing_type: "包材计费类型",
  Minimum_charge_amount: "最低计费金额",
  Number_of_warnings_must_not_exceed_the_quantity_in_stock:
    "预警数量不得超过库存数量",
  default_packaging_material: "默认包材",
  packingMaterialDetail: "包材详情",
  packing_material_info: "包材信息",

  //头程费用设置
  firstPassCostSetting: "头程费用设置",
  firstPassCost: "头程费", //菜单名称修改时注意
  freight_cost_cube_KG: "运费成本(立方/KG)",
  freight_cost: "运费成本",
  fixed_cost: "成本",
  profit: "利润",
  Please_enter_the_shipping_cost: "请输入运费成本",
  Surcharge_cost_cube_KG: "附加费成本(立方/KG)",
  Surcharge_cost: "附加费成本",
  Please_enter_the_Surcharge_cost: "请输入附加费成本",
  Out_of_range_freight_cube_KG: "超范围运费(立方/KG)",
  Out_of_range_freight: "超范围运费",
  Please_enter_Out_of_range_freight: "请输入超范围运费",
  Overreach_surcharge_cube_KG: "超范围附加费(立方/KG)",
  Overreach_surcharge: "超范围附加费",
  Please_enter_Overreach_surcharge_cube_KG: "请输入超范围附加费",
  Minimum_number_of_charging_parties: "最小计费方数",
  Please_enter_Minimum_number_of_charging_parties: "请输入最小计费方数",
  Start_greater_than: "起始(>)",
  cutoff: "截止(<=)",
  additional_charge_cube_KG: "附加费(立方/KG)",
  additional_charge: "附加费",
  Minimum_chargeable_weight: "最小计费重量",
  Please_enter_Minimum_chargeable_weight: "请输入最小计费重量",
  The_added_fee_is_empty: "添加的费用为空",
  The_added_cost_has_a_null_value_The_creation_failed_Please_modify_the_cost_and_try_again:
    "添加的费用存在空值，创建失败，请修改后重试",
  The_start_of_the_added_fee_is_not_equal_to_the_end_Please_modify_it_and_try_again:
    "添加的费用起始不等于截止，请修改后重试",
  Volume_weight_calculation_method_cubic_divide_volume_weight_is_worth_the_result_compare_the_weight_which_big_which:
    "体积重计算方式：立方/体积重值得出结果，比较重量，哪个大取哪个",
  There_are_no_packing_materials_for_the_warehouse: "暂无该仓库的包材",
  Printing_control: "正在打印第",
  Several_tasks: "个任务",
  code: "代码",
  await: "等待",
  second: "秒",
  request_timeout: "请求超时",
  Stop_query: "停止查询",
  Edit_the_entry_slip: "编辑入库单",

  //固定SKU费用
  fixedSkuFees: "固定SKU费用",
  Added_fixed_SKU_fee: "添加固定SKU费用",
  Select_Add_SKUs: "选择添加SKU",

  //仓租费
  storageCharges: "仓租费", //菜单名称修改时注意
  List_of_warehouse_rental_charges: "仓租费列表",
  listWarehouseRentalCharges: "仓租费列表", //菜单名称修改时注意
  Start_days: "开始天数",
  time_Info: "时间信息",
  Warehousing_charge: "仓租费用",
  Days_in_storage: "入仓天数",
  warehousing_time: "入仓时间",
  edit_cost: "编辑费用",
  please_enter_SKU: "请输入SKU",
  Operator_query: "操作人查询",
  Minimum_length_of_three_sides: "最小三边长",
  Maximum_length_of_three_sides: "最大三边长",
  The_input_fee_cannot_be_negative: "输入的费用不能为负数",
  Storage_rental_days_are_calculated_at_the_beginning_of_each_SKU_incoming_batch:
    "每个SKU入库批次开始计算仓租天数",

  //贴标费
  labellingCharge: "贴标费", //菜单名称修改时注意
  Label_quantity: "贴标数量",
  Label_or_not: "是否贴标",

  //财务报表
  Financial_statement: "财务报表",
  financialStatement: "财务报表", //菜单名称修改时注意
  tableConfigurationList: "表配置列表",
  Add_configuration_table: "添加配置表",
  table_name: "表名",
  table_ID: "表ID",
  table_name_data_type: "表名数据类型",
  tier: "层级",
  Required_or_not: "是否必填",
  Enable_or_not: "是否启用",
  Add_field: "添加字段",
  field_name: "字段名",
  field_ID: "字段ID",
  Field_data_type: "字段数据类型",
  to_lead: "导入",
  Multiple_table_import: "多表导入",
  Serial_number_multi_table_import: "序列号多表导入",
  inportSuccess: "导入成功",
  Drag_the_file_here: "将文件拖到此处",
  Only_xls_xlsx_csv__files_can_be_uploaded_and_the_size_does_not_exceed_100_MB:
    "只能上传xls/xlsx/csv文件，且不超过100MB",
  formulaConfiguration: "公式配置", //菜单名称修改时注意
  Add_a_single_table_formula: "添加单表公式",
  Edit_a_single_table_formula: "编辑单表公式",
  Add_a_multi_table_formula: "添加多表公式",
  Edit_table_formula: "编辑表公式",
  List: "表",
  data_processing_type: "数据处理类型",
  operator_symbol: "操作符",
  summation: "求和",
  Specified_data: "指定数据",
  Marker_text: "标记文字",
  de_weight: "去重",
  equation: "公式",
  equation_Name: "公式名",
  Sign_Color: "标记颜色",
  Result_field: "结果字段",
  Selection_formula: "选择公式",
  Selection_result_List: "选择结果表",
  Please_select_the_result_table: "请选择结果表",
  Only_one_result_table_can_be_selected: "仅允许选择一张结果表",
  resultList: "结果表",
  serial_number: "序列号",
  Serial_number: "流水号",
  Please_enter_the_serial_number: "请输入流水号",
  Please_upload_the_certificate: "请上传凭证",
  include: "包含",
  delete_batch: "删除批次",
  Specified_number_of_rows: "指定行数",
  Batch_process_field_data_types: "批量处理字段数据类型",
  Please_enter_the_serial_number_of_the_batch_you_want_to_delete:
    "请输入需要删除批次的序列号",
  editFinancialStatement: "编辑财务报表",
  Add_trigger: "添加触发器",
  Collection_management: "收账管理",
  Please_enter_the_tracking_number_separated_by_commas_or_newlines:
    "请输入运单号,用逗号或换行隔开",
  Please_enter_the_head_order_number_separated_by_a_comma_or_a_newline:
    "请输入头程订单号,用逗号或换行隔开",
  Please_enter_the_receipt_number_separated_by_a_comma_or_a_newline:
    "请输入入库单号，用逗号或换行隔开",
  registerReturnOrder: "退货收货",
  returnOrderGrounding: "退货上架",
  Number_of_remaining_shelves: "剩余上架数量",
  please_enter_Number_of_shelves: "请填写上架数量",
  Batch_registration_basket: "批量登记篮子",
  registration_basket: "登记篮子",

  //退货
  sales_return: "退货",
  return_management: "退货管理",
  returnManagement: "退货管理", //菜单名称修改时注意
  returnList: "退货列表",
  tracking_number: "跟踪号",
  Claim_status: "认领状态",
  date_issued: "上架时间",
  valid_time: "有效时间",
  Create_a_return_form: "创建退货单",
  Edit_a_return_form: "编辑退货单",
  Receiving_warehouse: "收货仓库",
  logistics_provider: "物流商",
  Return_note: "退货备注",
  processing_scheme: "处理方案",
  Product_picture: "商品图片",
  Select_add_item: "选择添加货品",
  Claim_time: "认领时间",
  Temporary_storage_number: "暂存号",
  viewReturnNote: "查看退货单", //菜单名称修改时注意
  Claimed: "已认领",
  unclaimed: "未认领",
  Parcel_information: "包裹信息",
  Package_picture: "包裹图片",
  Package_details: "包裹详情",
  Number_of_shelves: "上架数量",
  unqualified_quantity: "不合格数量",
  Quantity_of_abandonment: "废弃数量",
  // Return_Basket: '退货篮子',
  Image: "图片",
  Return_StockUnit: "退货库位",
  // Actual_Weight: '真实重量',
  // Actual_Lenth: '真实长度',
  // Actual_Width: '真实宽度',
  // Actual_Height: '真实高度',
  Receiving_Warehouse: "收货仓库",
  Claim_ReturnOrderMsagess:
    "当前包裹未登记，继续填写信息点击保存后将会登记至认领区！",
  Please_enter_the_tracking_number: "请输入运单号",
  Please_enter_the_warehouse: "请选择收货仓库",
  Please_enter_the_logistics_provider: "请输入物流商",
  Please_enter_the_temporary_number: "请输入暂存号",
  Please_enter_the_sku: "请选择SKU",
  Package_picture_isNotNull: "包裹图片不能为空",
  ReturnOrder_ItemProduct_IsNotSkuQuantityNull: "退货项商品SKU数量不能为空",
  ReturnOrder_ItemProduct_IsNotQuantityNull: "退货项商品数量不能为空",
  Please_add_the_return_order_item: "请添加退货商品",
  Reject: "驳回",
  Abandon: "废弃",
  Ground_Quantity: "已上架数量",
  Stay_Ground_Quantity: "待上架数量",
  Please_select_the_basket: "请扫描退货篮子",
  Handle_Remark: "处理备注",
  Please_add_the_commodity: "请添加产品",
  Please_fill_in_the_required_information: "请填写明细项真实尺寸信息",
  Please_enter_the_unqualified_quantity: "请输入不合格数量",
  The_unqualified_quantity_cannot_be_greater_than_the_product_quantity:
    "不合格数量不能大于商品数量",
  Generate_shelf_task: "指派上架任务",
  Please_enter_the_solution: "请输入处理方案",
  Please_upload_pictures: "请上传图片",
  //quality_testing:'质检',
  Return_StockUnits: "退货库存",
  Actual_Weight: "真实重量(kg/个)",
  Actual_Lenth: "真实长",
  Actual_Width: "真实宽",
  Actual_Height: "真实高",
  Actual_Size: "真实长宽高(CM)",
  Return_Basket: "退货篮子",
  ThirdParty_Warehousing: "三方仓",
  Determine_whether_to_reject: "是否确定驳回",
  Determine_whether_to_abandon: "是否确定废弃",
  returnOrderStayGrounding: "退货单待上架列表",
  Please_select_a_product_that_belongs_to_this_customer: "请选择该客户所属商品",
  Viewing_Delivery_logs: "查看发货日志",
  Reprint_face_sheet: "补印面单",
  By_default_the_quantity_of_reserved_storage_is_regarded_as_the_qualified_quantity_during_batch_inspection:
    "批量质检时默认以预约入库数量作为合格数量,产品默认已有标，是否继续？",
  When_the_return_form_of_the_claim_area_is_unclaimed_for_more_than_30_days_it_will_automatically_enter_the_destruction_area:
    "当认领区退件单超过30天无人认领，将自动进入销毁区",
  Failure_to_claim_within_30_days_automatically_enters_the_destruction_zone:
    "30天未认领自动进入销毁区",
  claim: "认领",

  //刊登
  publish: "刊登",
  publishLazada: "Lazada刊登", //菜单名称修改时注意
  publishShopee: "Shopee刊登", //菜单名称修改时注意
  publishTikTok: "TikTok刊登", //菜单名称修改时注意
  tiktokGoodsOnline: "TikTok在线商品",
  lazadaGoodsOnline: "Lazada在线商品",
  shopeeGoodsOnline: "Shopee在线商品",
  Goods_to_be_released: "待发布商品",
  CNSC_Goods_to_be_released: "CNSC待发布商品",
  Global_commodity: "全球商品",
  Site_goods: "站点商品",
  Link_ID: "链接ID",
  Link_Name: "链接名称",
  Link_SKU: "链接SKU",
  Variant_ID: "变体ID",
  master_map: "主图",
  onLineSKU: "线上SKU",
  pair: "配对",
  Paired_inventory_SKUs: "配对",
  pairLog: "配对日志",
  unpair: "解除配对",
  Modified_pairing: "修改配对",

  //平台授权
  PlatformAuthorization: "平台授权",
  store_management: "店铺管理",
  ShopAuthorization: "店铺授权",
  shopAuthorization: "店铺授权", //菜单名称修改时注意
  Add_authorization: "添加授权",
  name_of_shop: "店铺名称",
  Please_enter_the_name_of_the_shop: "请输入店铺名称",
  Platform_store_name: "平台店铺名称",
  authorization_status: "授权状态",
  be_authorized: "已授权",
  unauthorized: "未授权",
  privileged_time: "授权时间",
  After_authorization_automatically_pull_orders_within_half_a_month_automatically_pull_returns_within_half_a_month_and_online_product_information_within_half_a_year:"授权后自动拉取半个月内的订单，自动拉取半个月的退件，半年内的在线商品信息",
  After_authorization_automatically_pull_online_products_that_have_been_updated_within_half_a_year:"授权后自动拉取半年内存在更新的在线商品",
  Platform_SKUs_have_changed_please_pay_attention_to_re_pairing_otherwise_the_original_platform_SKUs_and_stock_SKUs_are_still_paired:"平台sku有变更，请注意重新配对，否则仍然是原平台SKU和库存sku配对",
  Replace_the_pairing_the_goods_information_of_the_previous_order_will_not_be_replaced_with_the_newly_paired_goods_please_go_to_the_order_list_to_modify_the_goods:"更换配对，之前订单商品信息不执行更换新配对的商品，请到订单列表修改商品。",
  After_the_dissolution_of_the_relationship_the_product_information_of_the_previous_order_will_not_be_unpaired_After_the_manual_pairing_the_new_pairing_logic_will_not_be_executed_for_the_previously_paired_order_Please_go_to_the_order_list_to_modify_the_product:"解除关系后，之前订单商品信息不执行解除配对关系，再手动配对后，之前已配对过的订单不执行新配对的逻辑，请到订单列表修改商品。",

  //WMS任务控制
  WMSmissionContro: "WMS任务控制", //菜单名称修改时注意
  Configuration_task_type: "配置任务类型",
  please_select_Configuration_task_type: "请选择配置任务类型",
  Distribution_task_generation_control_type: "配货任务生成控制类型",
  please_select_Distribution_task_generation_control_type:
    "请选择配货任务生成控制类型",
  Task_order_quantity: "任务订单数量",
  Please_enter_the_Task_order_quantity: "请输入任务订单数量",
  Maximum_order_number: "最大订单数",
  Please_enter_the_Maximum_order_number: "请输入最大订单数",
  Allocation_inquiry: "配货查询",
  Whether_to_allocate_goods_by_floor: "是否按楼层配货",
  please_select_Whether_to_allocate_goods_by_floor: "请选择是否按楼层配货",
  Whether_the_storage_space_can_be_allocated: "备货库位是否可配货",
  please_select_Whether_the_storage_space_can_be_allocated:
    "请选择备货库位是否可配货",
  Order_allocation_timeout_time: "订单配货超时时间",
  Please_enter_the_Order_allocation_timeout_time: "请输入订单配货超时时间",
  Generate_timeout_order_tasks_first: "优先生成超时订单任务",
  please_select_Generate_timeout_order_tasks_first:
    "请选择优先生成超时订单任务",
  Match_and_send: "边配边发",
  please_select_Match_and_send: "请选择边配边发",
  Delivery_weighing: "是否发货称重",
  please_select_Delivery_weighing: "请选择是否发货称重",
  Do_you_want_to_take_photos: "是否发货拍照",
  please_select_Do_you_want_to_take_photos: "请选择是否发货拍照",
  Check_whether_the_goods_are_delivered: "是否发货验货",
  please_select_Check_whether_the_goods_are_delivered: "请选择是否发货验货",
  Whether_the_whole_sorting_task_is_shipped: "是否整分拣任务发货",
  please_select_Whether_the_whole_sorting_task_is_shipped:
    "请选择是否整分拣任务发货",
  Delivery_inquiry: "发货查询",
  New_task_control: "新增任务控制",
  Edit_task_control: "编辑任务控制",
  Allocation_information: "配货信息",
  shipping_information: "发货信息",
  Multi_floor_combination_distribution: "多楼层组合配货",
  Distribute_goods_while_shipping: "边配货边发货",
  Total_allocation_quantity: "总配货数量",
  Total_shipment_quantity: "总发货数量",
  Delivery_phase: "发货阶段",
  Delivery_start_time: "发货开始时间",
  Delivery_end_time: "发货结束时间",
  Abnormal_query: "异常查询",
  need: "需要",
  do_without: "不需要",
  Please_enter_basket_remarks: "请输入篮子备注",
  Failed_to_print_sheet_please_rescan: "打印面单失败，请重新扫描",
  Single_print_success: "面单打印成功",
  batch_delivery: "批量发货",
  Bulk_shipment_quantity: "批量发货数量",
  Whether_to_scan_the_database: "是否扫描库位",
  Please_confirm_that_the_goods_have_been_returned_to_the_original_warehouse:
    "请确认已将商品放回原库位",
  Return_the_order_to_ungenerated: "将订单退回至未生成",
  Return_the_order_to_a_draft: "将订单退回至草稿",
  Item_task_list: "单品任务列表",
  itemTaskList: "单品任务列表", //菜单名称修改时注意
  Multi_task_list: "多品任务列表",
  multiTaskList: "多品任务列表", //菜单名称修改时注意
  Packing_material_condition: "包材状态",
  Basket_quantity: "篮子数量",
  viewWarehouseEntryList: "查看入库单列表",
  No_combo_SKU_images_uploaded: "请上传组合SKU图片",
  Whether_sorting_and_delivery_is_required: "是否需要分拣发货",
  delivery_process: "发货流程",
  delivery_process_one: "发货流程1",
  delivery_process_two: "发货流程2",
  Delivery_flow_2_does_not_allow_the_use_of_border_variations:
    "发货流程2不允许使用边配边分",
  Delivery_process_2_Multi_floor_distribution_is_not_allowed:
    "发货流程2不允许使用多楼层配货",
  Default_delivery_process_scanning_the_corresponding_basket_for_goods_sorting_and_delivery:
    "默认发货流程，扫描对应篮子进行货品分拣发货",
  Goods_are_sorted_and_no_baskets_are_needed_for_delivery:
    "货品进行分拣，发货不再需要篮子",
  Multiple_products_and_edge_matching_edge_division_unusable_volume:
    "多品与边配边分不可使用容积",
  Use_the_delivery_flow_for_multi_product_tasks_2_The_border_variation_is_not_allowed:
    "多品任务使用发货流程2不允许使用边配边分",

  //WMS
  Exit_Printing:"退出打印",
  single_product: "单品",
  paperTask: "纸质任务", //菜单名称修改时注意
  multiProductPaperDistribution: "多品纸质配货", //菜单名称修改时注意
  singleProductPaperDistribution: "单品纸质配货", //菜单名称修改时注意
  obtainPaperDistributionOfSingleProduct: "获取单品纸质配货", //菜单名称修改时注意
  completeThePaperDistributionOfTheSingleProduct: "完成单品纸质配货", //菜单名称修改时注意
  obtainMultiProductPaperDistribution: "获取多品纸质配货", //菜单名称修改时注意
  completeMultiProductPaperDistribution: "完成多品纸质配货", //菜单名称修改时注意
  Paper_tracking_number: "纸质单号",
  basket: "篮子",
  basket_Info: "篮子信息",
  Individual_product_operation: "单品操作", //菜单名称修改时注意
  Multiple_product_operation: "多品操作",
  multipleProductOperation: "多品操作", //菜单名称修改时注意
  multiple_products: "多品",
  order_quantity: "订单数量",
  volume: "容积",
  describe: "描述",
  taskList: "任务列表", //菜单名称修改时注意
  Extra_packing_material: "额外包材",
  listOrdersShipped: "待发货订单列表", //菜单名称修改时注意
  Order_phase: "订单阶段",
  order_source: "订单来源",
  Quantity_occupied: "占用数量",
  The_address_of_the_bill_is_empty_Contact_the_administrator:
    "面单地址为空,请联系管理员处理",
  Please_scan: "请扫描",
  Please_scan_the_barcode: "请扫描商品条码",
  Modify_the_number_of_SKUs: "修改SKU数量",
  Modified_warehouse: "修改仓库",
  Submitted_to_be_reviewed: "提交至待审核",
  Commit_back_not_generated: "提交回未生成",
  Back_to_not_generated: "退回至未生成",
  Back_to_draft: "退回至草稿",
  priority: "优先",
  secondary: "次要",
  order_inquiry: "订单查询",
  info_query: "信息查询",
  Print_basket_number: "打印篮子编号",
  Back_to_basket_list: "返回篮子列表",
  Sorting_starts_numbering: "分拣开始编号",
  Sorting_end_number: "分拣结束编号",
  Tripartite_warehouse_information: "三方仓信息",
  Threeway_warehouse_OP_number: "三方仓OP号",
  Batch_sorting: "批量分拣",
  The_sorting_number_of_the_current_scan_is: "当前扫描的分拣编号为",
  Print_the_sorting_basket_head: "打印分拣篮子头",
  List_of_shipped_orders: "已发货订单列表",
  listShippedOrders: "已发货订单列表", //菜单名称修改时注意
  taskID: "任务ID",
  There_are_no_tasks_in_the_basket: "当前篮子暂无任务",
  bulk_print: "批量打印",
  to_do_list_ID: "任务单ID",
  task_type: "任务类型",
  List_of_tasks_in_allocation: "配货中任务列表",
  listTasksAllocation: "配货中任务列表", //菜单名称修改时注意
  Sorting_task_list: "分拣中任务列表",
  sortingTaskList: "分拣中任务列表", //菜单名称修改时注意
  List_of_tasks_being_shipped: "发货中任务列表",
  listTasksBeingShipped: "发货中任务列表", //菜单名称修改时注意
  Printing_successfully_Stopped_printing: "打印成功,停止打印",
  List_of_shipped_tasks: "已发货任务列表",
  listShippedTasks: "已发货任务列表", //菜单名称修改时注意
  explosiveOperation: "爆款操作", //菜单名称修改时注意
  explosiveDistribution: "爆款配货", //菜单名称修改时注意
  explosiveDelivery: "爆款发货", //菜单名称修改时注意
  explosiveList: "爆款列表", //菜单名称修改时注意
  Order_origin_currency: "订单原始货币",
  not_finished: "未结束",
  have_not_started: "未开始",
  If_the_printing_is_not_successful_for_more_than_5_seconds_the_printing_ends:
    "打印超过5秒没捕获到成功状态,打印结束",
  Whether_it_is_a_paper_task: "是否为纸质任务",
  Checking_the_sheet: "正在检测面单",
  A_sheet_is_being_printed: "正在打印面单",
  System_deduction: "系统扣费",
  Get_the_distribution_task: "获取配货任务",
  Reprint_the_distribution_sheet: "补印配货单",
  invoice: "配货单",
  HOT: "爆款",
  print_TaskId: "打印任务ID",
  Generating_task: "正在生成任务",
  distributionControl: "配货控制", //菜单名称修改时注意
  Order_distribution_time_zone: "订单配货时区",
  Order_distribution_sorting_field: "配货排序字段",
  automatic_printing: "自动打印",
  Non_automatic_printing: "不自动打印",
  Automatic_printing_of_distribution_slips: "自动打印配货单",
  Automatically_prints_the_task_ID: "自动打印任务ID",
  The_time_zone_already_exists: "该时区已存在",
  Add_Time_zone: "添加时区",
  example: "示例",
  Ungenerated_task: "未生成任务",
  completed_distribution: "已完成配货",
  Order_submission_time: "订单提交时间",
  Please_select_automatic_printing: "请选择自动打印",
  Please_select_order_distribution_sorting_field: "请选择配货排序的字段",
  The_entered_time_zone_must_be_longer_than_one_hour_from_the_existing_time_zone:
    "输入的时区与现有时区需大于一个小时",
  The_time_zone_must_be_between_0100_and_2300:
    "输入的时间必须在01：00-23：00之间",
  Support_input_time_zone_return_to_add_the_input_time_zone_needs_to_exist_in_the_selection_value:
    "可直接选择时间，可输入时区回车进行添加，注：输入时区需存在选择值中",
  Sort_by_order_submission_time_from_morning_to_night:
    "以订单提交时间从早到晚进行排序",
  The_order_is_sorted_by_the_repository_number_and_the_next_repository_order_task_will_be_performed_only_after_processing_the_current_repository_order_task:
    "以库位号进行排序，处理完当前库位订单任务后才会进行下一个库位订单任务",
  Sort_by_sku_the_current_sku_is_completed_before_proceeding_to_the_next_sku:
    "按sku进行排序，完成当前sku后才会进行下一个sku",
  Generate_earlier_time_zone_tasks_first: "优先生成更早的时区任务",
  If_the_order_distribution_time_zone_is_not_set_it_will_not_be_divided_by_time_Only_the_order_distribution_sorting_field_will_be_sorted_to_generate_the_order:
    "如果不设置订单配货时区，就不会进行按照时间分割。只会按照订单配货排序字段，排序进行生成订单。",
  Pre_0_yesterday_orders_are_preferentially_generated:
    "优先生成0点前(昨日)订单",
  Generate_orders_from_0000_to_1200: "生成00:00至12:00时间段内的订单",
  Generate_orders_after_1200_to_before_2400: "生成12:00后至24:00前的订单",
  Please_enter_the_number_of_tasks: "请输入任务数量",
  Please_select_a_single_order_type: "请选择单种订单类型",
  Please_scan_task_ID: "请扫描任务ID",
  Mixed_shipments_are_possible_for_orders_of_different_task_types:"不同任务类型的订单可进行混合发货",

  //手工出入库
  manualEntryAndExitList: "手工出入库列表", //菜单名称修改时注意
  Manual_Entry_and_exit_slip_number: "手工出入库单号",
  Manual_Entry_and_exit_slip_type: "手工出入库类型", //手工出入库类型
  Manual_Entry_and_exit_slip_status: "手工出入库状态", //手工出入库状态
  accomplish_Time: "完成时间",
  audits: "审核",
  Create_ManualEntryAndExit: "创建手工出入库单",
  Please_fill_in_the_audit_remarks: "请填写审核备注",
  audit_remarks: "审核备注",
  pass_the_audit: "审核通过",
  The_inventory_will_be_changed_after_clicking: "点击确定后库存将会变化",
  Clicking_OK_will_flag_the_exception: "点击确定将标记为异常",
  Click_OK_and_it_will_be_rejected: "点击确定后将会被驳回",
  Number_of_shelvesd: "货架数量",
  Type_of_outgoing_and_incoming_accounts: "出入账类型",
  If_a_recommended_repository_exists_the_recommended_repository_is_selected_by_default:
    "存在推荐库位时，默认选中推荐库位",
  If_a_recommended_repository_exists_the_recommended_repository_is_entered_by_default:
    "存在推荐库位时，默认输入推荐库位",

  //账单明细
  Accounting_in: "入账",
  Accounting_out: "出账",
  in_out_account: "出入账",
  Sku_and_quantity: "商品SKU和数量",
  Billing_weight: "计费重量",
  Charge_quantity: "费用设置计费件数",
  Charge_weight: "费用设置重量",
  Cost_setting_Cube: "费用设置立方",
  cost_setting_Three_sides_length_CM: "费用设置三边和",
  Cost_set_three_sides_long: "费用设置三边长",
  goods_cobe: "商品立方",
  goods_Three_sides_length_CM: "商品三边长",
  goods_weight: "商品重量",
  type_of_fee: "费用类型",
  Item_code: "货品编码",
  Fill_in_the_positive_balance_of_recharge_and_fill_in_the_negative_balance_of_deduction:
    "充值余额填写正数，扣除余额填写负数",

  //出入库记录
  stock_in_and_stock_out_record: "出入库记录",
  storage_location_number: "库位号",
  Entry_quantity: "库存变动数量",
  total_inventory_quantity: "总库存数量",
  Current_batch_inventory_quantity: "当前批次库存数量",
  Batch_stock_quantity: "库位总库存数量",
  Batch_occupied_inventory_quantity: "批次占用库存数量",
  life: "库龄",
  Has_stock: "有库存",
  No_stock: "无库存",
  Has_No_stock: "有无库存",
  stock_type: "库存类型",
  Entry_time: "入库时间",
  Tripartite_storage: "三方仓库存",
  General_stock: "普通库存",
  Entry_and_exit_records: "查看库存详情",
  grounding: "上架",
  Shelf_in_bulk: "批量上架",
  Number_of_shelvesds: "上架数量",
  Unshelved: "未上架",
  Already_listed: "已上架",
  Multiple_quantity: "多发数量",
  Shelf_batch: "上架批次",
  Check_the_storage_details: "查看出入库明细",
  operate_successfully: "操作成功",
  Get_a_shelf_task: "获取上架任务",
  Please_select_some_user: "上架人",
  Prioritize_shelving: "设置优先上架",
  Please_select_the_type_of_manual_entry_ticket: "请选择手工出入库单类型",
  Please_select_warehouse: "请选择仓库",
  Please_select_the_warehouse_location: "请选择库位",
  Please_scan_the_database_location: "请扫描库位",
  Select_date_time: "选择日期时间",
  please_select_stockId: "请选择仓库",
  please_select_stockzone: "请选择分区",
  please_select_shelf: "请选择货架",
  Please_scan_sku: "请扫描SKU",
  please_select_auditUser: "请选择审核人",
  Storage_type: "出入库类型",
  Billing_quantity: "计费数量",
  Cost_type: "费用类型",
  frozenBillingDetails: "冻结账单明细",
  storage_location_capacity: "库位容量",
  product_capacity: "产品使用容量",
  recommendstorage_location_type: "推荐库位类型",
  Operational_query: "操作查询",
  Please_scan_the_basket: "请扫描篮子编码！",
  The_number_of_shelves_cannot_be_greater_than_the_qualified_number:
    "上架数量不能大于合格数量",
  Whether_batch_shelves: "是否批量上架",
  Please_select_the_same_warehouse_entry_form_to_submit:
    "请选择同一仓库入库单进行提交！",
  The_database_bit_number_does_not_exist: "不存在该库位号",
  replacement_cost: "重置成本",
  Create_a_breakout_task: "创建爆款任务",
  Match_and_divide: "边配边分",
  matchAndDivide: "边配边分", //菜单名称修改时注意
  Online_SKU_images: "线上SKU图片",
  Seller_SKU: "卖家SKU",
  AbnormalRemarks: "异常备注",
  Store_SKU: "店铺SKU",
  Authorization_assignment: "授权赋值",
  Please_enter_the_AccessToken: "请输入AccessToken",
  Please_enter_the_RefreshToken: "请输入RefreshToken",
  APITokenInformation: "API令牌信息",
  authorization_management: "授权管理",
  authorization_message: "授权信息",
  companyName: "公司名称",
  Print_a_paper_distribution: "打印纸质配货单",
  Select_case_number: "选择箱号",
  Starting_box_number: "开始箱号",
  End_box_number: "结束箱号",
  picker: "拣货人",
  Pick_date: "拣货日期",
  print_time: "打印时间",
  sku_quantity: "sku数量",
  Refill_slip_number: "补扣单号",
  Whether_to_cancel_supplementary_deduction: "是否确定撤销补扣?",
  irredeemable: "无法重复撤销",
  This_item_is_out_of_stock: "该商品无库存",
  The_repository_does_not_exist: "不存在该库位",

  //入库单控制
  warehouseEntryControl: "系统控制列表",
  Add_the_entry_ticket_control: "添加系统控制",
  Edit_entry_slip_control: "编辑系统控制",
  Whether_to_open_excess_inspection: "是否开启超额质检",
  Control_the_number_of_boxes_generated_during_inspection_tasks:
    "控制质检任务生成箱数",
  Control_the_number_of_SKUs_generated_by_the_mount_task:
    "控制上架任务SKU生成种数",
  Create_entry_ticket_control: "创建系统控制",
  Enter_0_for_the_entire_build_task: "输入0为整单生成任务",
  Whether_To_return_the_package_to_take_photos: "是否退货包裹拍照",
  Control_The_number_of_boxes_generated_for_a_quality_inspection_task_indicates_the_number_of_boxes_generated_when_a_worker_obtains_a_quality_inspection_task_Set_0_to_the_entire_generation_task:
    "控制质检任务生成箱数为工人获取质检任务时生成的箱数，填写0为整单生成任务",
  Control_the_number_of_SKU_types_generated_for_the_shelf_task_to_the_number_of_SKU_types_generated_when_obtaining_the_shelf_task_Fill_in_0_for_the_entire_single_generation_task:
    "控制上架任务SKU生成种数为工人获取上架任务时生成的SKU种数，填写0为整单生成任务",
  Whether_to_take_a_picture_of_the_return_is_whether_to_take_a_picture_of_the_package_when_the_return_receipt_is_received:
    "是否退货拍照为退货单收货时是否包裹拍照",

  //子账号管理
  Subaccount_management: "子账号管理",
  subaccountList: "子账号列表", //菜单名称修改时注意
  Subaccount_number: "子账号编号",
  subaccount_name: "子账号名称",
  Please_enter_a_subaccount_name: "请输入子账号名称",
  Add_a_subaccount: "添加子账号",
  subAccountRoleInfo: "子账号信息", //菜单名称修改时注意
  payment_type: "支付方式",
  Whether_to_terminate_services: "是否终止业务",
  Subaccount_role: "子账号角色",
  Please_add_product: "请添加商品",
  An_empty_container_number_exists_Please_check_and_try_again:
    "存在空的箱号，请检查后重试",
  An_empty_number_exists_Please_check_and_try_again:
    "存在空的数量，请检查后重试",
  Submit_success: "提交成功！",

  //撤销订单
  cancellation_of_order: "取消订单",
  Cancel_a_task: "取消任务",
  Confirm_cancellation_of_order: "确认取消订单",
  Confirm_cancel_task: "确认取消任务",
  Order_operation: "订单操作",
  Cancel_an_order: "撤销订单",
  Undo_to_exception: "撤销到异常",
  Undo_to_ungenerated: "撤销到未生成",
  cancelOrder: "撤销订单", //菜单名称修改时注意
  Please_enter_the_order_number_and_tracking_number_Only_100_order_numbers_are_supported_at_a_time_separated_by_newlines:
    "请输入订单号、跟踪号，一次仅支持100个单号，以换行隔开",
  Order_cancellation_Pick_stock_please_return_the_stock_to_the_original_shelf_order_cancellation_directly_to_the_confirmation:
    "已拣货的订单撤销拣货库存,请将库存放回原货架,订单直接撤销到确认中",

  // 自动处理规则
  automaticProcessingRule: "自动处理规则",
  Rule_name: "规则名",
  Rule_type: "规则类型",
  priority_level: "优先级",
  Rule_description: "规则描述",
  Rule_subitem: "规则子项",
  Modification_time: "修改时间",
  Modified_successfully: "修改成功",
  content: "内容",
  Please_add_at_least_one_rule: "请至少添加一个规则",
  Please_select_the_rule_type_first: "请先选择规则类型",

  // 仓租账单明细
  listWarehouseRentalChargesDetail: "仓租账单明细详情",
  list_warehouse_rental_charges_detail: "仓租账单明细详情",
  Inventory_batch: "库存明细",

  // 管理端工作台
  There_are_unfinished: "存在未完成的",
  task: "任务",
  Whether_to_process: "是否前往处理",

  // 仓库节假日
  warehouseHolidayList: "仓库节假日列表",
  holidayDate: "节假日日期",

  // 盘点
  count_method: "盘点方式",
  Whether_to_automatically_generate_count_tasks: "是否自动生成盘点任务",
  Whether_to_freeze_inventory: "是否冻结库存盘点",
  whether_Difference_count: "是否差异盘点",
  count_setting: "盘点设置",
  When_there_is_an_count_surplus_or_count_deficit_the_next_round_of_count_tasks_will_be_generated_after_the_end_of_the_count_until_the_final_count:
    "当出现盘盈或盘亏的情况，盘点结束后将生成下一轮盘点任务，直至最终盘点。",
  Indicates_how_many_rounds_of_count_are_required_If_only_one_round_of_count_is_required_the_value_is_one_If_you_need_to_count_two_rounds_it_is_two_and_so_on:
    "表示需要进行几轮盘点，若只盘点一轮，则为1；若需盘点两轮，则为2，以此类推。",
  Count_round: "盘点轮次",
  max_count_round: "最大盘点轮次",
  The_types_of_count_tasks_are_divided_into_light_count_and_dark_count:
    "盘点任务类型分为明盘和暗盘。",
  If_the_light_count_is_selected_the_specific_number_of_SKU_inventory_to_be_counted_will_be_displayed_If_dark_count_is_selected_the_specific_number_of_SKU_inventory_to_be_counted_is_not_displayed:
    "若选择明盘，则会显示需盘点的SKU库存具体数量；若选择暗盘，则不显示需盘点的SKU库存具体数量。",
  Whether_multiple_rounds_of_count_can_be_the_same_person:
    "多轮盘点人是否可为同一人",
  If_the_automatic_generation_of_count_tasks_is_not_enabled_the_task_to_be_counted_is_not_automatically_generated:
    "若不开启自动生成盘点任务，则不会自动生成待盘点任务",
  If_you_need_to_restart_the_automatic_generation_of_the_count_task_the_time_of_the_automatic_generation_of_the_count_task_will_start_in_the_morning_of_the_next_day:
    "若需重新开启自动生成盘点任务，自动生成盘点任务的时间将在第二天的凌晨开始计时。",
  count_methods_are_divided_into_SKU_count_and_count_by_warehouse_location:
    "盘点方式分为按SKU进行盘点和按库位进行盘点。",
  According_to_the_SKU_count_all_the_storage_locations_that_have_this_SKU_stock_will_be_counted_and_only_the_time_of_the_last_SKU_count_will_be_updated:
    "按照SKU盘点，则将盘点所有存在此SKU库存的库位，且只会更新SKU最后一次盘点的时间。",
  If_the_count_is_taken_by_warehouse_location_all_SKUs_with_inventory_in_the_warehouse_location_will_be_counted_and_only_the_last_count_time_of_the_warehouse_location_will_be_updated:
    "按照库位盘点，则将盘点库位上所有存在库存的SKU，且只会更新库位的最后一次盘点时间。",
  Subtract_the_last_SKU_or_warehouse_count_time_according_to_the_current_days_find_the_qualified_data_and_generate_the_count_task:
    "按照当前天数减去SKU/库位最后一次盘点时间，找寻符合条件的数据，生成盘点任务。",
  The_cycle_of_the_count_task_is_automatically_generated:
    "自动生成盘点任务周期",
  Indicates_how_many_count_details_exist_in_an_automatic_count_task:
    "表示一个自动盘点任务中会存在多少条盘点明细。",
  For_example_if_the_task_method_is_SKUs_and_the_number_of_automatically_generated_tasks_for_library_locations_and_SKUs_is_ten_the_count_details_contain_10_different_SKUs:
    "例：当盘点方式为SKU且自动生成任务（库位/SKU）数量为10，则盘点明细将存在10条不同的SKU。",
  Automatic_generation_task: "自动生成任务",
  check_log: "查看日志",
  Not_less_than_two: "不可小于2",
  Quantity_of_need_to_counted: "需盘数量",
  Quantity_of_actually_count: "实盘数量",
  Quantity_of_wait_to_count: "待盘数量",
  Quantity_of_count_loss: "盘亏数量",
  Quantity_of_count_profit: "盘盈数量",
  task_number: "任务编号",
  finally: "最终",
  execution_time_of_count: "执行盘点时间",
  executor_of_count: "执行盘点人",
  execute_count: "执行盘点",
  check_count_details: "查看盘点明细",
  The_total: "总",
  Need_to_count_rounds: "需盘点轮次",
  current_count_rounds: "当前盘点轮次",
  counter: "盘点人",
  quantity_of_occupy_stock: "占用库存数量",
  quantity_of_disabled_stock: "不可用库存数量",
  storage_location_final_count_time: "库位最后盘点时间",
  product_final_count_time: "商品最后盘点时间",
  Manually_generate_count_task: "手动生成盘点任务",
  Designated_counter: "指定盘点人",
  Data_from_different_warehouses_exists_Ensure_that_the_selected_data_belongs_to_the_same_warehouse:
    "存在不同仓库的数据，请确认勾选的数据属于同一仓库！",
  The_warehouse_is_not_set_for_count_please_set_for_count_first:
    "该仓库未进行盘点设置，请先进行盘点设置",
  please_add_data_first: "请先添加数据！",
  count_task_number: "盘点任务编号",
  stock_batch: "库存批次",
  Number_of_quarantined_shelves: "隔离上架数量",
  Original_batch_shelf_time: "原批次上架时间",
  Isolated_storage_location: "隔离库位",
  Whether_the_inventory_is_out_of_storage_location: "是否非库位库存",
  Isolation_shelf_time: "隔离上架时间",
  Quarantine_inventory_shelving_task_number: "隔离库存上架任务编号",
  Mission_start_shelf_time: "任务开始上架时间",
  Mission_completed_shelf_time: "任务完成上架时间",
  Task_remarks: "任务备注",
  Original_storage_location: "原库位",
  Isolation_shelf_space: "隔离上架库位",
  Complete_the_task: "完成任务",
  Fetch_task: "获取任务",
  Complete_this_round_of_tasks: "完成本轮任务",
  Current_total_stock: "当前总库存",
  Real_time: "实时",
  Task_rotation: "任务轮次",
  Add_non_existing_stock_in_storage_location: "添加非现有库位库存",
  count_loss_reason: "盘亏原因",
  count_loss_remark: "盘亏备注",
  count_loss_type: "盘亏类型",
  Defective_product: "次品",
  lack: "缺少",
  Current_stock: "当前库存",
  Inventory_quantity_at_the_end_of_the_last_round: "上轮结束盘点时库存数量",
  Quantity_of_actually_count_of_last_round_count: "上轮实盘数量",
  Quantity_of_count_profit_of_last_round_count: "上轮盘盈数量",
  Quantity_of_count_loss_of_last_round_count: "上轮盘亏数量",
  End_time_of_last_round_count: "上轮盘点结束时间",
  count_checked_and_no_problem_stock: "盘点过，没问题的库存",
  Missing_or_damaged_quantity: "缺少/损坏的数量",
  The_amount_that_exceeds_the_real_time_current_inventory:
    "超过 当前库存(实时) 的数量",
  Whether_the_real_time_current_inventory_is_different_from_the_Inventory_quantity_at_the_end_of_the_last_round:
    "当前库存(实时)是否与上轮结束盘点时库存数量有差异",
  Please_complete_the_current_task_first: "请先完成当前任务",
  The_storage_location_does_not_exist_or_has_been_completed:
    "该库位不存在或已完成",
  The_bar_code_does_not_exist_or_has_been_completed: "该商品条码不存在或已完成",
  Please_scan_the_storage_location_and_scan_the_product_bar_code_and_enter_the_quantity_of_actually_count:
    "请先扫描库位、商品条码，并输入实盘数量",
  Cancel_count_task: "撤销盘点任务",
  count_details: "盘点详情",
  count_log: "盘点日志",
  Confirm_whether_to_cancel_the_count_task: "是否确认撤销盘点任务",
  countSetting: "盘点设置",
  stockStorageLocationList: "库存库位列表",
  countTaskList: "盘点任务列表",
  countTask: "盘点任务",
  IsolatedStorageLocationList: "隔离库位列表",
  isolatedShelfTaskList: "隔离上架任务列表",
  isolatedShelfTask: "隔离上架任务",
  pendingClaimList: "待赔付",
  countDetails: "盘点详情",
  Please_scan_the_storage_location: "请先扫描库位",
  please_confirm_exist_storage_location_data_first: "请先确认有库位数据存在",
  Isolated_storage_location_listing_task_number: "隔离库位上架任务编号",
  task_start_time: "任务开始时间",
  task_end_time: "任务结束时间",
  Whether_the_inventory_is_not_in_the_original_storage_location:
    "是否为非原库位库存",
  The_actual_number_of_products_on_shelves: "实际上架产品数量",
  the_time_that_product_listing_to_the_isolated_storage_location:
    "产品上架到隔离库位的时间",
  Please_scan_the_Original_storage_location: "请先扫描原始库位",
  please_confirm_exist_Original_storage_location_data_first:
    "请先确认有原库位数据存在",
  this_task_is_completed: "该任务为已完成状态",
  the_storage_location_do_not_exist_or_is_not_isolated_storage_location:
    "该库位不存在或不属于隔离库位",
  Whether_to_force_the_task_to_complete: "是否强制完成任务",
  The_inventory_has_been_counted_to_the_last_product_and_the_count_task_is_to_be_finished_Is_it_confirmed_that_it_is_completed:
    "已盘点至最后一个商品，即将结束盘点任务，是否确认完成",
  unoccupied_quantity: "未占用数量",
  occupied_quantity: "已占用数量",
  return_order: "退件单",
  manual_entry_and_exit: "手工出入库",
  Shifting_storage_location: "移库位",
  Shifting_warehouse: "移仓库",
  can_no_be_zero: "不能为0",
  When_this_feature_is_enabled_customers_will_not_be_able_to_use_this_inventory_to_submit_orders_during_the_count:
    "启用此功能，盘点时客户将无法使用此库存提交订单",
  Will_not_be_able_to_operate_this_inventory: "将不能操作此库存",
  If_the_whether_Difference_count_is_equal_yes_the_count_rounds_default_is_two_and_it_cannot_be_less_than_two:
    "如果是否差异盘点等于是，盘点轮次，默认变成2，且不可小于2。",
  It_is_used_to_control_the_maximum_count_rounds_when_there_is_a_difference_in_count_quantity:
    "用于控制出现差异盘点数量时，控制最大盘点轮次",
  modifiable: "可修改",
  When_there_is_no_difference_it_is_the_final_count_round:
    "出现无差异时则为最终盘点轮次",

  // 销量报表
  salesReportForSKU: "SKU销量报表",
  salesReportForWarehouse: "仓库销量报表",
  salesReportForPlatform: "平台销量报表",
  salesReportForCountry: "国家销量报表",
  salesReportForStore: "店铺销量报表",
  product_quantity_sold: "商品销售数量",
  Average_daily_sales: "平均每天销量",

  // 工作台
  goods_ranking_list: "商品排行榜",
  Bar_chart: "柱状图",
  weeks: "周",
  hot: "热销",
  unsalable: "滞销",
  Line_chart: "折线图",
  Total_account_balance: "账户总余额",
  Account_balance: "账户余额",
  Number_of_authorized_stores: "已授权店铺数量",
  Number_of_authorized_expired_stores: "授权过期店铺数量",
  Time_period: "时间段",
  Sales_volume: "商品销量",
  Commodity_inventory: "商品库存",
  You_have_an_expired_license_store_to_deal_with: "您有授权过期店铺待处理",
  years: "年",
  Historical_announcemen: "历史公告",
  This_year: "今年",
  Number_of_squares: "方数",
  shipment: "发货量",
  Release_time: "发布时间",
  Frozen_balance: "冻结余额",
  Frozen: "冻结",
  Store_shipment: "店铺发货量",
  is_total_orders_click_on_the_detailed_data_to_view_the_order_list:
    "的总出单量,点击详细数据可查看出单量列表",
  Real_time_total_orders_of_the_day_click_on_the_detailed_data_to_view_the_storage_order_list:
    "当天实时的总出单量,点击详细数据可查看仓储订单列表",
  Created_nearly_seven_days_ago: "创建时间近七日",
  Warehouse_order: "仓储订单",
  Does_not_include_today_is_sales: "不包含今日销量",
  Pie_chart: "饼状图",
  This_week: "本周",
  Current_month: "本月",
  Unread_announcement: "未读公告",
  months: "月",
  main_account: "主账号",
  head_entry: "头程入库",
  First_Leg_Order: "头程单",
  exception_First_Leg_Order: "异常头程单",
  In_the_stock_shelves: "入库上架中",
  Hello_welcome_to_use_estp_oms: "你好，欢迎使用易速菲OMS",
  customer_number: "客户编号",
  Top_up_immediately: "立即充值",
  Sales_Volume: "销量",
  Data_last_updated: "数据最近更新时间",
  Beijing_time: "北京时间",
  obligation_first_leg_order: "待付款头程单",
  Total_square_inventory: "库存总方数",
  go: "前往",
  Temporarily_unavailable_please_contact_the_administrator:
    "暂时无法查看，请联系管理员",
  The_operation_succeeds_and_The_data_is_refreshed_successfully:
    "操作成功，刷新数据成功",
  number_of_SKU: "SKU种数",
  dedicated_business_invitation_commissioner: "专属招商",
  dedicated_customer_service: "专属客服",
  inventory_all_quantity: "库存总数量",
  Total_number_inventory_in_transit: "在途库存总数量",
  Order_out_today: "今日出单量",
  Order_out_today_yesterday: "昨日出单量",
  Timeout_failed_to_complete_the_task: "超时未完成任务",
  Overdue_order: "逾期订单",
  Warehousing_order: "入库订单",
  Quantity_of_goods_in_stock_today: "今日入库上架单量",
  Number_of_SKUs_in_storage_today: "今日入库上架SKU数量",
  Return_order: "退货订单",
  Order_quantity_of_return_today: "今日退货上架单量",
  Number_of_SKUs_returned_today: "今日退货上架SKU数量",
};
