import instance from './interceptor'
import { Message,Loading } from 'element-ui';

/**
 * 核心函数，可通过它处理一切请求数据，并做横向扩展
 * @param {url} 请求地址
 * @param {params} 请求参数
 * @param {options} 请求配置，针对当前本次请求；
 * @param loading 是否显示loading
 */

function request(url,params,options={loading:false},headers,method,autoMessage=true){
  let loadingInstance;
  // 请求前loading
  if(options.loading)loadingInstance=Loading.service({
      target:'#main',
      spinner:'el-icon-loading',
      text:'加载中',
      background:"rgba(0, 0, 0, 0.6)"
  });
  return new Promise((resolve,reject)=>{
    let data = {}
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    const hours = ('0' + currentDate.getHours()).slice(-2);
    const minutes = ('0' + currentDate.getMinutes()).slice(-2);
    const seconds = ('0' + currentDate.getSeconds()).slice(-2);
    const milliseconds = ('00' + currentDate.getMilliseconds()).slice(-3); // 获取毫秒并保证始终占据3位数字
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    headers = headers || {
      "Content-Type": "application/json,charset=UTF-8",
      "Access-Control-Allow-Origin":"*",
      "X-Request-Date":formattedDate
    }
    // get请求使用params字段
    if(method =='get') data = {params}
    // post请求使用data字段
    if(method =='post') {
      data = {data:params}
    }
    instance({
      url,
      method,
      headers,
      ...data
    }).then((res)=>{
      // 此处作用很大，可以扩展很多功能。
      // 比如对接多个后台，数据结构不一致，可做接口适配器
      // 也可对返回日期/金额/数字等统一做集中处理
      if (res.code == 200 || res.Code == 200 || !autoMessage) {
        resolve(res);
      }else {
        Message.error(res.msg)
        reject(res);
      }
    }).catch((error)=>{
      console.log(error)
      reject(error);
      Message.error(error.msg)
    }).finally((error)=>{
      reject(error);
      if(loadingInstance) loadingInstance.close();
    })
  })
}



//封装get请求
function get(url,params,options){
  return request(url,params,null,options,'get')
}
//封装POST请求
function post(url,params,options,header,autoMessage){
  return request(url,params,options,header,'post',autoMessage)
}
//封装Upload请求
function upload(url,data,options){
  let loadingInstance;
  // 请求前loading
  if(options.loading)loadingInstance=Loading.service({
    target:'#main',
    spinner:'el-icon-loading',
    text:'加载中',
    background:"rgba(0, 0, 0, 0.6)"
  });
  return new Promise((resolve,reject)=>{
    instance({
      url,
      method:'post',
      headers:{
        "Content-type":'multipart/form-data',
        "Access-Control-Allow-Origin":"*",
      },
      data
    }).then((res)=>{
      // 此处作用很大，可以扩展很多功能。
      // 比如对接多个后台，数据结构不一致，可做接口适配器
      // 也可对返回日期/金额/数字等统一做集中处理
      if (res.code == 200 || res.Code == 200) {
        resolve(res);
      }else {
        Message.error(res.msg)
        reject(res);
      }
    }).catch((error)=>{
      console.log(error)
      reject(error);
      Message.error(error.msg)
    }).finally((error)=>{
      console.log(error)
      reject(error);
      if(loadingInstance) loadingInstance.close();
    })
  })
}
export default {
  get,post,upload
}
