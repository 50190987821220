// 角色
import * as type from "../mutation-types"
const state = {
  data:window.localStorage.getItem("role") || ""
}

const getters = {
  getRole:state=>state.data
}

const mutations = {
  [type.USER_OUT_LOGIN](state){
    state.data = null
  }
}

const actions = {
  onUse(response){
    window.localStorage.setItem("role",response)
  },
  outLogin({commit}){
    commit(type.USER_OUT_LOGIN)
    window.localStorage.removeItem("role")
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
