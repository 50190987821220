// 数据转换
// 转换格式：
// {
//   "pageSize": 0,
//   "pageIndex": 0,
//   "totalNum": 0,
//   "result": [
//     {
//       "fieldName": "string",
//       "conditionalType": "string",
//       "fieldValue": "string"
//     }
//   ]
// }

exports.install = (Vue) => {
  Vue.prototype.dataConversion = function(data, paging, queryType = []) {
    let newData = { ...paging };  //浅拷贝item.paging
    newData.result = [];  //定义一个空数组result
    for (var key in data) { //遍历data,将每个key对应的value赋值给result,默认操作符为0
      if (data[key] != null) {  //去除null
        if (data[key].length != 0) {  //这里要将value为空的项过滤掉
          if (Array.isArray(data[key])) { //除数组以外全部转为字符串
            newData.result.push({
              'fieldName':key,
              'fieldValue': data[key],
              "conditionalType": "0"
            });
          }else {
            newData.result.push({
              'fieldName':key,
              'fieldValue': `${data[key]}`,
              "conditionalType": "0"
            });
          }
        }
      }
    }
    newData.result.map((item,index) => {
      if (Array.isArray(item.fieldValue)) { //先判断值类型是否为数组
        if (item.fieldValue[0] instanceof Date) { //判断值类型是否为日期 
          const dateOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
          };
          let date = { ...item.fieldValue } //浅拷贝item.fieldValue
          const startFormattedDate = date[0].toLocaleString(  //格式化日期不足10位时前面补0
            undefined,
            dateOptions
          );
          const endFormattedDate = date[1].toLocaleString(  //格式化日期不足10位时前面补0
            undefined,
            dateOptions
          );
          newData.result.push({ //创建结束时间push进result
            'fieldName':item.fieldName,
            'conditionalType':"5",
            'fieldValue':endFormattedDate
          })
          newData.result[index].fieldValue = startFormattedDate;  //开始时间直接修改原来的值
          newData.result[index].conditionalType = "3";  //修改操作符
        }else{  //这里的数据为类似金额类区间值
          let data = {...item.fieldValue}
          newData.result.push({ //创建结束时间push进result
            'fieldName':item.fieldName,
            'conditionalType':"5",
            'fieldValue':data[1]
          })
          newData.result[index].fieldValue = data[0];  //开始值直接修改原来的值
          newData.result[index].conditionalType = "3";  //修改操作符
        }
      }
    })
    if (queryType.length != 0) {  //特殊查询:特殊查询需要传入一个queryType
      if (queryType[0].conditionalType == 6) {  //当特殊查询为6时需要进行特殊处理
        //6为或者，此时传入的fieldName格式需要是一个数组，根据数组来创建nuwData
        if (newData.result.length != 0) { //如果传入为空则不处理
          let fieldValue = newData.result[0].fieldValue
          newData.result = [{
            "ConditionalList":[]
          }]
          queryType[0].fieldName.map((item,index) => {
            newData.result[0].ConditionalList[index] = {  //批量查询多个字段
              'Key':1,
              'Value':{
                'conditionalType':"6",
                'fieldName':item,
                'fieldValue':fieldValue
              }
            }
          })
        }
      }else if(queryType[0].conditionalType == 1){  //模糊查询
        queryType.map((queryItem) => {
          queryItem.fieldName.map((item) => {
            newData.result.map((res) => {
              if (res.fieldName === item) {
                res.conditionalType = "1"
              }
            })
          })
        })
      }else {
        newData.result.map((Item) => {
          queryType.map((item) => {
            if (Item.fieldName === item.fieldName) {  //验证queryType与data中传入的字段相同，则代表该字段需要特殊查询
              Item.conditionalType = item.conditionalType
            }
          })
        })
      }
    }
    return newData
  };
}